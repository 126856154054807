import React from 'react'
import { useState } from 'react'
import "./contact.css"
import axios from "axios"

const url = "https://deepioticsacademy.herokuapp.com/contact"

const ContactMain = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [msg, setMsg] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(name,email,msg)
    try {
      const res = axios.post(url, {
        name, 
        email, 
        message:msg
      })
      setName("")
      setEmail("")
      setMsg("")
      alert("Your Message is Registered Succesfully.")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='Contact'>
      <div className="container">
        <div className="bottom">
          <div className="left">

            <div className="heading">
              <h1>Contact Us</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="inputBoxes">
                <div className='labelBox'>
                  <label className='formLabel'>Name:</label>
                </div>
                <input className='formInputs' type="text" minLength={3}  value={name} required onChange={(e) => setName(e.target.value)} />
              </div>

              <div className="inputBoxes">
                <div className='labelBox'>
                  <label className='formLabel' minLength="4">Email:</label>
                </div>
                <input className='formInputs' type="email" required  value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div className="inputBoxes">
                <div className='labelBox'>
                  <label className='formLabel'>Message:</label>
                </div>
                <textarea cols="30" rows="2" className='formInputs' value={msg}  onChange={(e) => setMsg(e.target.value)}></textarea>
              </div>
              <p className='information'>
                We will use this information to connect you with right person to answer your question and may send related content.By checking the box,you allow us to store and process the information submitted above for this purpose. You may unsubscribe from these communications at any time. For more information please review our Privacy Policy.
              </p>
              <div className="btnbox">
                <button className="btn">GET IN TOUCH</button>
              </div>
            </form>
          </div>
          <div className="right">
            <div className="map">

              <div className="google-map">
              </div>
            </div>
            <div className="address box">
              <h1>Address</h1>
              <h3>A-88, Vaibhav Nagar, Kanadiya Road,Indore</h3>
            </div>
            <div className="email box">
              <h1>Email</h1>
              <h3>academy@deepiotics.com</h3>
            </div>
          </div></div>
      </div>
    </div>
  )
}

export default ContactMain