import React from 'react'
import Sponsorship from './Sponsorship'

function SponsorshipSupport() {
    const sponsorships = [
        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650978712/DEGREE_HOLDER_fINAL_sucagp.jpg",
            heading: "Person with Disability",
            desc: "Welcome Great problem solver! We believe Great way to empower you is to enable you to earn.  No matter what field you're in, We offer courses in IT and Research from beginner, intermediate, to advanced level. We're open to help all of you, regardless of disability and location. If you're looking to start your career in IT and need sponsorship.",
        },
        
        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650978709/Sponsorship_Final_wvxcvx.jpg",
            heading: "Financially Weak Students",
            desc: "Everyone has the right to education so that they can improve their life. No matter where you come from or what you look like, If you are financially weak we support you to achieve your career goal in life with sponsorship. Aim higher than ever before.",
        }
    ]
    return (
        <div className='bg-[#003953]'>
            <div className="w-[95%] m-auto">

                <div className="p-3">
                    <h1 className='text-3xl font-semibold text-white'>Sponsorship Support</h1>
                    <p className='text-white text-justify py-3'>We support differently abled people  and Below poverty line students all over the world to start careers in IT/ Research with finding the sponsor Company for them . Select the course and apply for the same. Please become a bridge by sharing this to deserving people </p>
                </div>

                <div className="py-3">
                    {
                        sponsorships.map((value, index) => {
                            const rev = index % 2 === 0 ? false : true
                            return (
                                <Sponsorship src={value.src}  heading={value.heading} desc={value.desc} rev={rev } className="text-justify" key={index}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SponsorshipSupport