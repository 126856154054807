import React, { useEffect, useState } from 'react'


function Card(props) {
  return (
    <div data-aos="flip-left" className='w-full my-4 md:my-0 md:w-1/6 lg:w-1/6 mx-8 bg-white text-black rounded-3xl p-5'>
      <span className="bg-[#EA3453] font-semibold h-10 w-10 rounded-full flex justify-center items-center text-white relative -top-10 ">{props.no}</span>
      <div className="-mt-5">
        <p className='font-semibold'>{props.name}</p>
        <p>{props.desc}</p>
      </div>
    </div>
  )
}

const Sponsor = () => {

  const [scroll, setScroll] = useState(0);
  const [docHeight, setDocHeight] = useState(2000);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    })
    setDocHeight(document.body.scrollHeight - 1500)
  }, [])

  return (
    <>
    <div className='bg-[#003953] text-white flex flex-col justify-center items-center py-4'>
      <div className="w-3/4 my-2">
        <h1 className='text-2xl text-center font-semibold'>Sponsor A Candidate</h1>
        <p className='text-center leading-8'>Education is for everyone. Sponsor candidate with disability or financially weak student with our sponsorship program.</p>
      </div>

      <div className="flex flex-wrap md:justify-center py-6">
        <Card name={"Candidate Connect"} desc={"Once you share interest in sponsorship, we will connect deserving candidate(s) to be trained."} />
        <Card name={"Training Schedule"} desc={"Then we create a relevant and energizing training journey, that is in line with your budget and other requirements."} />
        <Card name={"Training Delivery "} desc={"The training are taught by experts in the field. You will get assessment of each sponsored candidate. "} />
        <Card name={"Add up in your team"} desc={"Hire your candidate as intern or employee or help them to get one !"} />
      </div>
    </div>
          <div className={`bg-[#EA3453] p-3 ${scroll >= docHeight ? '' : 'fixed'} w-[100vw] bg-opacity-90 z-[200] bottom-0 text-center text-white justify-between `}>
          <p className='text-2xl underline pb-2'>GET IN TOUCH</p>
          <button className='bg-[#003953] rounded-3xl p-3 text-lg font-semibold'>Book A Call</button>
        </div>
        </>
  )
}

export default Sponsor