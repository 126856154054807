import React from "react";
import Project from "./Project";

function LiveProjects(props) {
  return (
    <div>
      <img src="https://i.ibb.co/vsRWLG0/Polygon-4.png" alt="" className="" />
      <div className="w-[90%] mx-auto pb-10">
        {props.whatYouWillGet ? (
          <h1 className="text-xl md:text-2xl font-semibold py-6">
            What You Will Get
          </h1>
        ) : (
          <h1 className="text-xl md:text-2xl font-semibold py-6">
            Live Projects
          </h1>
        )}

        <div className="grid sm:grid-cols-2 md:grid-cols-3 py-4">
          {props.liveProjects.map((value, index) => {
            return <Project src={value.src} name={value.name} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default LiveProjects;
