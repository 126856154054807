import React, { useState, useEffect } from 'react'
import Modal from "react-modal"

Modal.setAppElement('#root')

function Modal2({ displayRazorpay, handleChange, setModalIsOpen, modalIsOpen, heading, amount, user }) {
    const [finalAmount, setFinalAmount] = useState(amount)

    const finalAmountCalc = (code) => {
        var tempArray = amount.split(" ", 2);
        var currAmount = Number(tempArray[1]);

        if (code == 'AY10') {
            var FinAmount = currAmount - 0.1 * (currAmount);
            setFinalAmount(`${currAmount} - ${0.1 * (currAmount)} = INR ${FinAmount}`)
            alert("Code Applied Successfully. You Got 10% Discount")
        }
        else if (code == 'ZX50') {
            var FinAmount = currAmount - 0.5 * (currAmount);
            setFinalAmount(`${currAmount} - ${0.5 * (currAmount)} = INR ${FinAmount}`)
            alert("Code Applied Successfully. You Got 50% Discount")
        }
        else if (code == 'ZX25') {
            var FinAmount = currAmount - 0.25 * (currAmount);
            setFinalAmount(`${currAmount} - ${0.25 * (currAmount)} = INR ${FinAmount}`)
            alert("Code Applied Successfully. You Got 25% Discount")
        }
        else {
            setFinalAmount(amount)
            alert("Invalid Coupon")
        }
    }

    return (
        <Modal className="" onRequestClose={() => { setModalIsOpen(false) }} isOpen={modalIsOpen}>
            <div className='flex flex-col lg:flex-row'>
                <div className=' w-[100%] md:w[60%]'>
                    <h1 className="text-center text-xl p-3 font-bold z-[100] ">Enter Your Details To Proceed.</h1>
                    <div className="mt-4 flex flex-col justify-center items-center">
                        <label htmlFor="Name" className="p-3">Name</label>
                        <input type="text" name="name" onChange={handleChange} className="rounded-2xl w-[100%] md:w-[60%]" required />
                        <label htmlFor="Name" className="p-3">Email</label>
                        <input type="text" name="email" onChange={handleChange} className="rounded-2xl w-[100%] md:w-[60%]" required />
                        <label htmlFor="Name" className="p-3">Contact</label>
                        <input type="text" name="contact" onChange={handleChange} className="rounded-2xl w-[100%] md:w-[60%]" />
                        <div className="flex flex-col p-2 md:block">
                            <label htmlFor="Coupon" className="p-3 block text-center">Coupon Code</label>
                            <input type="text" name="code" onChange={handleChange} className="rounded-2xl m-1 w-[100%] md:w-[60%]" />
                            <button className='bg-slate-500 text-white  p-2 m-2 rounded-3xl' onClick={() => { finalAmountCalc(user.code) }}>Check Code</button>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className="text-center text-xl p-3 font-bold z-[100] ">Payment Details</h1>
                    <h3 className='m-8 mt-20'><b>Course Selected: </b> {heading}</h3>
                    <h3 className='m-8'><b>Amount: </b> {amount}</h3>
                    <h3 className='m-8'><b>Final Amount: </b> {finalAmount}</h3>
                    <button type="button" className="block m-auto  items-center p-2 px-5 font-bold rounded-3xl bg-[#EA3453] border-2 border-black" required onClick={displayRazorpay}>Proceed To Pay</button>

                </div>
            </div>
        </Modal>
    )
}

export default Modal2