import React from 'react'

function Timeline({view,setView, ...props}) {
    return (
        <div className="flex-col flex md:w-1/3">
            {
                props.content.map((value,idx) => {
                    return (
                        <div className="flex items-center my-4" onClick={()=>setView(idx)} key={idx}>
                            <div className="bg-[#EA3453] font-semibold h-10 w-10 rounded-full flex justify-center items-center text-white">{idx + 1}</div>
                            <p className={`${view===idx?'text-white':'text-[#ffffff81]'} whitespace-nowrap hover:text-white cursor-pointer px-4 font-semibold text-sm md:text-lg`}>{value.week === "Graduation" ? "Graduation" : `Week ${value.week}`}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Timeline