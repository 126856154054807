import React from 'react'
// import Layout from "../../components/Layout";
import { useState } from 'react'
import axios from "axios"
import "./businessContact.css"

const url = "https://deepioticsacademy.herokuapp.com/training"

const Training = () => {
  const [name,setName]=useState("")
  const [company,setCompany]=useState("")
  const [role,setRole]=useState("")
  const [email,setEmail]=useState("")
  const [contact,setContact]=useState("")
  const [training,setTraining]=useState("")
  const [msg,setMsg]=useState("")


  const handleSubmit=(e)=>{
    e.preventDefault()
    console.log(name,company,contact,
        email,training,role,
        msg)
    try {
      const res = axios.post(url,{
        name,
        company,
        phoneNumber:contact,
        email,
        trainingOn:training,
        role,
        message:msg
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
      <div className='Sponsor'>
        <div className="container">
          <div className="bottom">
            <div className="left">
              
              <div className="heading">
                <h1>Looking for a specific training for your employees ?</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Username</label>
                  </div>  
                  <input className='formInputs' type="text" minLength={3} required onChange={(e)=>setName(e.target.value)}/>
                </div>
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Company Name</label>
                  </div>  
                  <input className='formInputs' type="text" minLength={3} required onChange={(e)=>setCompany(e.target.value)}/>
                </div>
                
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel' minlength="4">Role</label>
                  </div>
                  <input className='formInputs' type="text" required onChange={(e)=>setRole(e.target.value)}/>
                </div>
                
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel' minlength="4">Email</label>
                  </div>
                  <input className='formInputs' type="email" required onChange={(e)=>setEmail(e.target.value)}/>
                </div>
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Phone </label>
                  </div>  
                  <input className='formInputs' type="tel" minLength={10} onChange={(e)=>setContact(e.target.value)}/>
                </div>

                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel' minlength="4">Looking for training on:</label>
                  </div>
                  <input className='formInputs' type="text" required onChange={(e)=>setTraining(e.target.value)}/>
                </div>
                
    
                
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Message</label>
                  </div>
                  <textarea cols="30" rows="2" className='formInputs' onChange={(e)=>setMsg(e.target.value)}></textarea>
                </div>
                <div className="btnbox">
                  <button className="btn">GET IN TOUCH</button>
                </div>   
              </form>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Training