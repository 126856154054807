import React from "react";

function Project(props) {
  return (
    <div className="w-1/1 h-1/1 flex justify-center align-center">
      <div className="rounded-xl mx-auto my-4 bg-[#003953] flex flex-col justify-center items-center text-white py-20 w-2/3 md:mx-4">
        <div className="bg-gray-400 w-20 h-20 rounded-full mb-8">
          <img src={props.src} alt="" />
        </div>
        <p className="font-semibold text-center w-[90%]">{props.name}</p>
      </div>
    </div>
  );
}

export default Project;
