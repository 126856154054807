import OurAdvisors from "./components/Landing/OurAdvisors/OurAdvisors";
import FlexibleProgram from "./components/Landing/FlexibleProgram/FlexibleProgram";
import Layout from "./components/Layout";
import SponsorshipSupport from "./components/Landing/SponsorshipSupport/SponsorshipSupport";
import Whyus from "./components/Landing/WhyUs/WhyUs";
import Frontpage from "./components/Landing/FrontPage/Frontpage";
import Courses from "./components/Landing/Courses/Courses";
import Program from "./components/CoursesPage/Programs/Program";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MeetTheInstructor from "./components/CoursesPage/MeetTheInstructor/MeetTheInstructor";
import IndustryValidatedCurriculam from "./components/CoursesPage/IndustryValidatedCurriculum/IndustryValidatedCurriculam";
import Faq from "./components/CoursesPage/FAQ/Faq";
import LiveProjects from "./components/CoursesPage/LiveProjects/LiveProjects";
import WhyToChoose from "./components/CoursesPage/WhyToChose/WhyToChoose";
import Benifits from "./components/CoursesPage/Benifits/Benifits";
import WhoShouldJoin from "./components/CoursesPage/WhoShouldJoin/WhoShouldJoin";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Section1 from "./components/Business/Section1";
import Section2 from "./components/Business/Section2";
import Section3 from "./components/Business/Section3";
import Section4 from "./components/Business/Section4";
import Section5 from "../src/components/Business/section5";
import ContactMain from "../src/components/Contact/ContactMain";
import About from "./components/About/About";
import Sponsor from "./components/BusinessContact/Sponsor";
import Training from "./components/BusinessContact/Training";
import Hire from "./components/BusinessContact/Hire";
import Partner from "./components/BusinessContact/Partner";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      easing: "ease-in-sine",
    });
    AOS.refresh();
  }, []);

  const ContactPage = () => {
    return (
      <Layout>
        <ContactMain />
      </Layout>
    );
  };

  const HireContact = () => {
    return (
      <Layout>
        <Hire />
      </Layout>
    );
  };

  const PartnerContact = () => {
    return (
      <Layout>
        <Partner />
      </Layout>
    );
  };

  const TrainingContact = () => {
    return (
      <Layout>
        <Training />
      </Layout>
    );
  };

  const SponsorContact = () => {
    return (
      <Layout>
        <Sponsor />
      </Layout>
    );
  };

  const LandingPage = () => {
    return (
      <Layout>
        <Frontpage />
        <Courses />
        <Whyus />
        <FlexibleProgram />
        <OurAdvisors />
        <SponsorshipSupport />
      </Layout>
    );
  };

  const CoursesPage = (props) => {
    return (
      <Layout>
        <Program
          heading={props.heading}
          description={props.description}
          aboutHeading={props.aboutHeading}
          bookUrl={props.bookUrl}
          aboutContent={props.aboutContent}
          courseProperty={props.courseProperty}
        />
        <WhyToChoose
          whyHeading={props.whyHeading}
          whyDescription={props.whyDescription}
          whyItems={props.whyItems}
        />
        <Benifits />
        <WhoShouldJoin
          whoShouldJoin={props.whoShouldJoin}
          whoDesc={props.whoDesc}
        />
        <MeetTheInstructor instructors={props.instructors} />
        <IndustryValidatedCurriculam
          industryValidatedCurriculam={props.industryValidatedCurriculam}
        />
        <LiveProjects
          liveProjects={props.liveProjects}
          whatYouWillGet={props.whatYouWillGet || false}
        />
        <Faq
          heading={props.heading}
          faqs={props.faqs}
          fees={props.fees}
          startDate={props.startDate}
          seats={props.seats}
        />
      </Layout>
    );
  };
  const BusinessPage = (props) => {
    return (
      <Layout>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </Layout>
    );
  };
  const AboutPage = (props) => {
    return (
      <Layout>
        <About />
      </Layout>
    );
  };

  return (
    <BrowserRouter>
      <div className="overflow-x-hidden">
        <Routes>
          {/* Landin Page  */}
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/business" element={<BusinessPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/sponsor-a-candidate" element={<LandingPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/business" element={<BusinessPage />} />

          <Route exact path="/sponsor" element={<SponsorContact />} />
          <Route exact path="/training" element={<TrainingContact />} />
          <Route exact path="/hire" element={<HireContact />} />
          <Route exact path="/partner" element={<PartnerContact />} />

          {/* Python For Everyone  */}
          <Route
            exact
            path="/python-for-everyone"
            element={
              <CoursesPage
                heading="Python for Everyone"
                description="Learn Python from experienced
              Data Scientists at Deepiotics"
                aboutHeading="Start your IT Career"
                bookUrl="https://calendly.com/deepiotics-academy/30-minute-python-free-live-demo"
                aboutContent={[
                  "A precise Seven-week course has been designed by an experienced team of international AI experts & Data Scientist. Python is the most widely used programming language and the preferred language for Machine Learning, Data Science, Artificial Intelligence, Automation, Data Analysis and Research experiments in the world. You will need an excellent knowledge of Python to get those high-paying positions, which you will get through this course.",

                  "You will be able to apply confidently for Python programming employment after completing the course. Yes, even if you have never programmed before, this applies to you.",

                  "So, what are you waiting for? Learn Python in a fun and practical method that can help you enhance your career and expertise.",
                ]}
                courseProperty={{
                  duration: "7 weeks",
                  classSchedule: "Morning / Evening / Weekend Classes",
                  commitment: "4-8 hr/ week",
                  language: "Hindi , English",
                  teachingMode:
                    "Hands-on, applied projects, online  Interactive  labs, 1:1 Doubt Solving",
                  certification: "On successful completion of Course.",
                  courseMaterial: "Notebooks, PPTs, Cheatsheet",
                  fees: "8000 Rs. /-",
                  introVideo:
                    "https://www.youtube.com/watch?v=hEgO047GxaQ&ab_channel=Telusko",
                }}
                whyHeading="Why To Choose Python?"
                whyDescription="Python developers are in growing demand but there is a shortage of experts who have mastered the language. There are immense job opportunities in various domains for Python experts."
                whyItems={[
                  "From start-ups to enterprises Python developers job boards are full of vacancies. Demand is evergreen.",
                  "Rewarding pay scales. From NASA, Google to your neighborhood start-up everyone needs Python developers.",
                  "Python is widely used in web development, data analysis, machine learning, AI, Raspberry Pi, and more.",
                  "It’s simple syntax and ease make it an excellent choice for beginners and non-programming experience People",
                ]}
                liveProjects={[
                  {
                    name: "Time Speeding Calculator",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Rock Paper Scissors",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Random Password Generator",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                ]}
                industryValidatedCurriculam={[
                  {
                    week: 1,
                    content: [
                      {
                        heading: "Core Python",
                        desc: [
                          "Introduction to python and its’ prospects",
                          "Python features and application",
                          " Anaconda Python Installation",
                          "Working with Jupyter Notebook",
                          "Working with Cloud",
                        ],
                      },
                      {
                        heading: "Enter the world of Python",
                        desc: [
                          "Fundamentals of python",
                          "Concept of Variable, Input & output",
                          "String manipulation",
                          " String Indexing and Slicing",
                          " String functions and method",
                          "Concept of operators",
                        ],
                      },
                    ],
                  },

                  {
                    week: "2-3",
                    content: [
                      {
                        heading: "Python Control Flow Statement and Function",
                        desc: [
                          "Introduction to Flow Control",
                          "Working with conditional Statements (if, elif, else)",
                          "Introduction to Loop concept",
                          "While loop and For Loop",
                          " Transfer Statements (Break, Continue, Pass)",
                        ],
                      },

                      {
                        heading: "Python Data Structure and algorithms",
                        desc: [
                          "Solve more complex problems with Python.",
                          "Learn to use the right data structures for every scenario.",
                          "Start writing recursive algorithms.",
                        ],
                      },
                    ],
                  },
                  {
                    week: 4,
                    content: [
                      {
                        heading: "Collections",
                        desc: ["Introduction to collections"],
                      },
                      {
                        heading: "List",
                        desc: [
                          "List objects creation",
                          "List indexing and slicing",
                          "Applying iterations on list objects",
                          "Nested lists",
                          "Eliminating duplicate elements of list",
                          "Working with methods of list",
                          "Sorting elements of a list",
                          "Searching for a required element in list",
                          "List comprehensions",
                        ],
                      },
                      {
                        heading: "Tupple",
                        desc: [
                          "Tuple objects creation",
                          " Tuple indexing and slicing",
                          " Applying iterations on tuple objects",
                          " Nested Tuples",
                          " Storing list objects in Tuples",
                          " Differences between list and tuple",
                          " Working with methods of tuple",
                        ],
                      },
                      {
                        heading: "Set",
                        desc: [
                          " Creating set objects",
                          "Applying iterations on set objects",
                          "Performing set operations on set objects",
                          "Working with methods of Set",
                          " Set comprehensions",
                        ],
                      },
                    ],
                  },
                  {
                    week: "5-6",
                    content: [
                      {
                        heading: "Function and Modules",
                        desc: [
                          " Defining and Calling a Function",
                          " Function Parameters and Arguments",
                          " Types of Arguments",
                          " Print vs Return",
                          " Recursive Function",
                          " Global and Local Variables",
                          " Lambda Function",
                          " Working  with modules",
                        ],
                      },
                      {
                        heading: "Exception and File Handling",
                        desc: [
                          "Introducing the Error and its types",
                          "Exception and need of Exception Handling",
                          "Try, Except and finally clauses",
                          "Learn about file and file handling",
                          "Operation on file",
                          "Reading data from a file",
                          "Writing data to a file",
                        ],
                      },
                    ],
                  },
                  {
                    week: "7",
                    content: [
                      {
                        heading: "Code Repository",
                        desc: [
                          "Learn how to version your code with Git and share it with your team",
                        ],
                      },
                      {
                        heading: "Projects",
                        desc: [
                          "Number Guessing Game",
                          "Watching Movie Eligibiity",
                          "Word Counter",
                        ],
                      },
                      {
                        heading: "Live Project",
                        desc: [
                          "Time Speeding Calculator",
                          "Rock Paper Scissors",
                          "Random Password Generator",
                        ],
                      },
                    ],
                  },

                  {
                    week: "Graduation",
                    content: [
                      {
                        heading: "Graduation",
                        desc: [
                          "Congratulations! You’re now ready to apply Python, and ready to kick-start your IT career.",
                          "You will receive a Deepiotics Academy certificate",
                          "You will receive relevant job alerts",
                        ],
                      },
                    ],
                  },
                ]}
                instructors={[
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg",
                    name: "Dr. Bhawna Nigam ",
                    designation: "Ph.D. in Machine Learning",
                    desc: "Sr. Data Scientist, an avid academician and researcher extensively worked with industry on  computer vision, deep learning and machine learning. She has 12 + years of Hands-on experience leveraging Artificial Intelligence to solve challenging business problems specially in Healthcare with 30+ Research Publications.",
                  },

                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Vijay_anyi7q.jpg",
                    name: "Mr. Vijay Birchha ",
                    designation: "M.E. Software Engineering",
                    desc: "Having 10+ years of experience in Data Science and Analytics with product architecture design and delivery. Working at Deepiotics with various product and service based companies. Having an experience of 5+ years in educating people and helping them to make a career transition. He is pursuing Ph.D. in Early Cancer Detection using Deep Learning.",
                  },

                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Shraddha_gjhx9t.jpg",
                    name: "Ms. Shraddha Gupta",
                    designation: "M.Tech, Data Science & Forecasting",
                    desc: "Having 2+ years of experience in developing AI Based products and implementing Python. She likes to share her learning with Python enthusiasts. She has worked on healthcare and computer vision related products.",
                  },
                ]}
                whoDesc="This course is a perfect choice for everyone.  Our students come from varied backgrounds like Computer Engineering, Sales, Doctors, Radiologists and Artists. Python is for everyone."
                whoShouldJoin={[
                  {
                    name: "Beginner",
                    desc: "First-time programmers who have never programmed before.",
                  },
                  {
                    name: "Technology leaders",
                    desc: "Expand career in Data Analysis, Data Science, Artificial Intelligence, Web Development, QA Automation.",
                  },
                  {
                    name: "Non Computer Background",
                    desc: "Professionals from Engineering, Sales, Doctors, Dentistry, Radiologists,  Artists so on who are willing to shift their career in IT.",
                  },
                  {
                    name: "Researcher",
                    desc: "Anyone wants to learn coding for Research experiments.",
                  },
                ]}
                faqs={[
                  {
                    question: "What happens if I can’t make a live session?",
                    answer:
                      "No problem, we record all live sessions and share them with the cohort.",
                  },
                  {
                    question:
                      "I work full-time, what is the expected time commitment?",
                    answer: "We estimate 6-10 hours per week.",
                  },
                  {
                    question: "Do I need to be a technical expert?",
                    answer:
                      "It's not required, but the course includes technical content and it might be a steep learning curve for participants who have absolutely no technical background.",
                  },
                  {
                    question: "How can I solve my coding doubts",
                    answer: "Weekly 1:1 Doubt clearing session will be held.",
                  },
                  {
                    question: "Can I take a break during the Course?",
                    answer:
                      "You can pause the course and resume after a break within two months.",
                  },
                ]}
                fees="INR 8000"
                startDate="1st June 2022"
                seats={10}
              />
            }
          />

          {/* Data Science  */}
          <Route
            exact
            path="/data-science"
            element={
              <CoursesPage
                heading="Data Science Certification"
                description="Learn to develop Data Science for Industry from experienced Data Scientists at Deepiotics"
                aboutHeading="Start Your Data Science Journey"
                bookUrl="https://calendly.com/deepiotics-academy/30-minute-data-science-free-live-demo"
                aboutContent={[
                  "A rigorous eight-week course has been created by an experienced team of  Data Scientists. Data science is an interdisciplinary discipline that uses scientific methods, procedures, algorithms and system to extract information and knowledge from noisy, structured, and unstructured data, as well as to apply that knowledge and actionable insights across a variety of application domains.This Data Science certification provides in-depth instruction on the most in-demand Data Science and Machine Learning skills, as well as hands-on experience with major tools and technologies. To get such high-paying positions, you will need a strong understanding of Data Science, which you will learn in this certification.                ",

                  "You will be able to apply confidently for Data Science employment after completing the course. You can become employable and valued in the eyes of future employers if you master the necessary skills, which you will learn in this course.",

                  "So, what are you waiting for? Learn Data Science in a fun and practical method that can help you enhance your career and expertise.",
                ]}
                courseProperty={{
                  duration: "8 weeks",
                  classSchedule: "Morning / Evening / Weekend Classes",
                  commitment: "4-8 hr/ week",
                  language: "Hindi , English",
                  teachingMode:
                    "Hands-on, applied projects, online  Interactive  labs, 1:1 Doubt Solving",
                  certification: "On successful completion of Course.",
                  courseMaterial: "Notebooks, PPTs, Cheatsheet",
                  fees: "12000 Rs. /-",
                  introVideo:
                    "https://www.youtube.com/watch?v=hEgO047GxaQ&ab_channel=Telusko",
                }}
                whyHeading="Why To Choose Data Science?"
                whyDescription="Data science is expected to generate 11.5 million employment by 2026, making it the fastest-growing job. There are very few candidates available for recruitment. As a result, Data Science is a very employable field.              "
                whyItems={[
                  "Data Science has made a huge difference among the healthcare, banking, consulting, and e-commerce industries.",
                  "70% of the job postings in this sector are for data scientists with less than five years of work experience.",
                  "As the industry races to harness the power of data, the demand for data scientists is increasing at a fast pace.",
                  "The average data scientist's salary is $114k.",
                ]}
                liveProjects={[
                  {
                    name: "Salary Prediction using Linear Regression",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Diabetes Prediction using Logistic Regression",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Liver Patient Disease classification using Naive Bayes",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },

                  {
                    name: "dvertising prediction using SVM",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Car Evaluation using Decision Tree",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Bank Note authentication prediction using Random Forest",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Text classification using K-nearest neighbors",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                  {
                    name: "Mall customer prediction using k-means",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                  {
                    name: "Market Basket Analysis using Apriori Algorithm",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                ]}
                industryValidatedCurriculam={[
                  {
                    week: "1",
                    content: [
                      {
                        heading: "Introduction",
                        desc: [
                          "Introduction to Data Science",
                          "Data Science applications in Healthcare, Banking, Marketing, Ecommerce, Automobile, HR, Advertising",
                          "Data Scientist profession and career, Data Science Job Opportunities",
                          "Data Science techniques, Four Flavors of Data Science",
                        ],
                      },
                      {
                        heading: "Data Preprocessing",
                        desc: [
                          "Understanding of data format, Analyze your data with descriptive statistics.",
                          "Feature Selection, Feature Engineering , Filtering, Handling missing value.",
                          "Remove duplicates, Data transformation , Data Plotting, Statistical Charts, Data Preparation.",
                        ],
                      },
                      {
                        heading: "Project",
                        desc: ["Data preparation of Salary prediction"],
                      },
                    ],
                  },

                  {
                    week: "2",
                    content: [
                      {
                        heading: "Machine Learning",
                        desc: [
                          "Introduction to Machine Learning, Machine Learning Framework.",
                          "Characteristics  of machine learning, Supervised and Unsupervised learning.",
                          "Classification and Regression, Introduction to Clustering.",
                        ],
                      },
                      {
                        heading: "Evaluation of Algorithm",
                        desc: [
                          "Measuring Accuracy Score, Loss Function, Confusion Matrix.",
                          "Regularization, Precision-Recall Trade-off, ROC Curve, Overfitting and Underfitting.",
                        ],
                      },
                    ],
                  },
                  {
                    week: "3-4",
                    content: [
                      {
                        heading: "Supervised Learning Algorithm",
                        desc: [],
                      },
                      {
                        heading: "Linear Regression",
                        desc: [
                          "Prediction using Regression Line, Implementation of Linear Regression Model, Model evaluation.",
                          "Salary Prediction using Linear Regression.",
                        ],
                      },
                      {
                        heading: "Logistic Regression",
                        desc: [
                          "Data preparation of Diabetes Dataset, Train the model of predication, Model evaluation",
                          "Diabetes Prediction using Logistic Regression",
                        ],
                      },
                      {
                        heading: "Naive Bayes",
                        desc: [
                          "Bayes Theorem and its extension, How does algorithm work, Implementation of Naive Bayes Classifier, Model evaluation.",
                          "Liver Patient Disease classification using Naive Bayes.",
                        ],
                      },
                    ],
                  },
                  {
                    week: "5-6",
                    content: [
                      {
                        heading: "Support Vector Machine",
                        desc: [
                          "Introduction to Support Vector Machine,  Implementation, Model evaluation.",
                          "Advertising Prediction using SVM",
                        ],
                      },
                      {
                        heading: "Decision tree ",
                        desc: [
                          "Representation of data using  Decision Tree, Implementation, Model evaluation.",
                          "Car Evaluation using Decision Tree",
                        ],
                      },
                      {
                        heading: "Random Forest",
                        desc: [
                          "Introduction to Random Forest Algorithm, Implementation, Model evaluation.",
                          "Bank Note authentication prediction using Random Forest",
                        ],
                      },
                      {
                        heading: "K- Nearest Neighbors",
                        desc: [
                          "Classification using K-nearest neighbors,  Implementation, Model evaluation.",
                          "Text classification using K-nearest neighbors",
                        ],
                      },
                    ],
                  },
                  {
                    week: "7-8",
                    content: [
                      {
                        heading: "Unsupervised Learning Algorithm",
                        desc: [],
                      },
                      {
                        heading: "K-means Clustering",
                        desc: [
                          "Introduction, Implementation, Model evaluation",
                          "Mall customer prediction using k-means",
                        ],
                      },
                      {
                        heading: "Apriori Algorithm",
                        desc: [
                          "Introduction to Apriori Algorithm, Key components, Implementation, Model evaluation",
                          "Market Basket Analysis using Apriori Algorithm",
                        ],
                      },
                      {
                        heading: "Model Deployment",
                        desc: [
                          "Saving the Model",
                          "Retrain Model",
                          "API development for Model",
                          "Web interface development using streamlit",
                          "Connect model API with web interface",
                        ],
                      },
                    ],
                  },
                  {
                    week: "Graduation",
                    content: [
                      {
                        heading: "Graduation",
                        desc: [
                          "Congratulations! You are now a Master in Data Science, and ready to kick-start your career.",
                          "You will receive a Deepiotics Academy certificate",
                          "You will receive relevant job alerts",
                        ],
                      },
                    ],
                  },
                ]}
                instructors={[
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg",
                    name: "Dr. Bhawna Nigam ",
                    designation: "Ph.D. in Machine Learning",
                    desc: "Sr. Data Scientist, an avid academician and researcher extensively worked with industry on  computer vision, deep learning and machine learning. She has 12 + years of Hands-on experience leveraging Artificial Intelligence to solve challenging business problems specially in Healthcare with 30+ Research Publications.",
                  },

                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Vijay_anyi7q.jpg",
                    name: "Mr. Vijay Birchha ",
                    designation: "M.E. Software Engineering",
                    desc: "Having 10+ years of experience in Data Science and Analytics with product architecture design and delivery. Working at Deepiotics with various product and service based companies. Having an experience of 5+ years in educating people and helping them to make a career transition. He is pursuing Ph.D. in Early Cancer Detection using Deep Learning.",
                  },

                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Shraddha_gjhx9t.jpg",
                    name: "Ms. Shraddha Gupta",
                    designation: "M.Tech, Data Science & Forecasting",
                    desc: "Having 2+ years of experience in developing AI Based products and implementing Python. She likes to share her learning with Python enthusiasts. She has worked on healthcare and computer vision related products.",
                  },
                ]}
                whoDesc="The Data Science role requires a combination of experience, data science expertise, and the appropriate tools and technologies. It is an excellent job option for both new and experienced workers. The Data Science certification course is best suited for aspirant professionals with an analytical mindset from any educational background/ Industry."
                whoShouldJoin={[
                  {
                    name: "Beginner",
                    desc: "First-time programmers who have never programmed before.",
                  },
                  {
                    name: "Technology leaders",
                    desc: "Expand career choices in Data Science, Machine Learning, Artificial Intelligence, Computer Vision, Deep Learning.",
                  },
                  {
                    name: "Non Computer Background",
                    desc: "Shift career in Data Science in their industry like Engineering, Sales, Doctors, Dentistry, Radiologists and Artists.",
                  },
                  {
                    name: "Entrepreneurs and business owners",
                    desc: "Who wants to use data science strategies for decision-making.",
                  },
                ]}
                faqs={[
                  {
                    question: "What happens if I can’t make a live session?",
                    answer:
                      "No problem, we record all live sessions and share them with the cohort.",
                  },
                  {
                    question:
                      "I work full-time, what is the expected time commitment?",
                    answer: "We estimate 6-10 hours per week.",
                  },
                  {
                    question: "Do I need to be a technical expert?",
                    answer:
                      "It's not required, but the course includes technical content and it might be a steep learning curve for participants who have absolutely no technical background.",
                  },
                  {
                    question: "How can I solve my coding doubts",
                    answer: "Weekly 1:1 Doubt clearing session will be held.",
                  },
                  {
                    question: "Can I take a break during the Course?",
                    answer:
                      "You can pause the course and resume after a break within two months.",
                  },
                ]}
                fees="INR 12000"
                startDate="20 June"
                seats={10}
              />
            }
          />

          {/* Python For Data Analysis */}
          <Route
            exact
            path="/python-for-data-analysis"
            element={
              <CoursesPage
                heading="Certification in Data Analytics"
                description="Learn  to analyze, visualize and utilize Data for Business Decisions from experienced Data Analyst at Deepiotics"
                bookUrl="https://calendly.com/deepiotics-academy/30-minute-data-analysis-free-live-demo-clone"
                aboutHeading=""
                aboutContent={[
                  "A rigorous Ten-week course created by an experienced team of Data Analysts.  Data analytics capabilities are just too critical for staying competitive. It has gained popularity from a few industries to nearly every industry and market. You'll learn how to plan data analysis solutions and data analytic processes along with how to apply statistics and predictive analytics to real-world datasets. Also how to work with SQL databases and build data visualizations.",

                  "You'll be able to confidently apply for Data Analyst employment after completing the course. You can become employable and valued in the eyes of future employers if you master the necessary skills, which you will learn in this course.",

                  "So, what are you waiting for?  Learn Data Analysis skills in a fun and practical method that can help you enhance your career and expertise.",
                ]}
                courseProperty={{
                  duration: "10 weeks",
                  classSchedule: "Morning / Evening / Weekend Classes",
                  commitment: "4-8 hr/ week",
                  language: "Hindi , English",
                  teachingMode:
                    "Hands-on, Applied Projects, Online Labs, 1:1 Doubt Solving",
                  certification: "On successful completion of Course.",
                  courseMaterial: "Notebooks, PPTs, Cheatsheet",
                  fees: "18000 Rs. /-",
                  introVideo:
                    "https://www.youtube.com/watch?v=hEgO047GxaQ&ab_channel=Telusko",
                }}
                whyHeading="Why you should learn Data Analysis"
                whyDescription="The demand for data analytics is increasing, and it will soon be a fundamental element of every organization. Top companies are turning to data analytics to find new markets for their services and products."
                whyItems={[
                  "90% of the world’s top companies will have converged Data analytics initiatives by 2023.",
                  "Data analytics is at the top of the list of technologies to watch in the next three years.",
                  "Data analytics are required in every industry because of the shortage of analytical skills, you can work in a variety of industries.",
                  "Data analytics as a skill plays a critical role in deciphering the future of businesses.",
                ]}
                liveProjects={[
                  {
                    name: "Data Analytics for Sales data",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Data Analytics for Student performance",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Data Analytics for Stock Market",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                ]}
                industryValidatedCurriculam={[
                  {
                    week: "1-2",
                    content: [
                      {
                        heading: "Introduction",
                        desc: [
                          "Introduction to Data Analysis",
                          "Data Analysis in different Industry, Various Use cases",
                          "Different roles in the organizations, Working with Jupyter Notebook",
                          "Working with Cloud",
                        ],
                      },
                      {
                        heading: "SQL",
                        desc: [
                          "Introduction to SQL, Entity-Relationship Model, Database Creation",
                          "Table Creation, Working with SQL commands",
                          "Keys, SQL Operators, SQL Functions, Join",
                          "Views and stored procedure, Subqueries",
                        ],
                      },
                    ],
                  },

                  {
                    week: "3-4",
                    content: [
                      {
                        heading: "Data Transformation using Excel",
                        desc: [
                          "Introduction to Excel",
                          "Data Transformation",
                          "Data Analysis function",
                          "Working with a different file format",
                        ],
                      },
                      {
                        heading: "Mathematical Computing using NumPy",
                        desc: [
                          "Introduction to NumPy",
                          "Create and manipulate the NumPy array",
                          "Class and Attributes of ndarray",
                          "Array indexing and Slicing",
                          "Mathematical functions of NumPy",
                          "Different Operation On ndarray",
                          "Analyze IPL Dataset using Numpy",
                        ],
                      },
                    ],
                  },
                  {
                    week: "5-6",
                    content: [
                      {
                        heading: "Data Manipulation with Pandas",
                        desc: [
                          "Introduction to Pandas, Understanding of DataFrame",
                          "Concept of indexing, Deal with text data using pandas",
                          "Deal with CSV file, Statistical analysis using pandas",
                          "Handling missing values , File read and write support",
                          "Different operations using pandas, Plotting with pandas",
                          "Analyze Olympic Dataset using Pandas",
                        ],
                      },
                      {
                        heading: "Data Visualization using Matplotlib",
                        desc: [
                          "Introduction to Data Visualization, Introduction to Matplotlib",
                          "Use matplotlib with data, Create visualization of raw data",
                          "Plot and subplots, Different plotting types, Read and extract the information from charts",
                        ],
                      },
                    ],
                  },
                  {
                    week: "7-8",
                    content: [
                      {
                        heading: "Statistical Analysis",
                        desc: [
                          "Introduction to statistical analysis, Measure of central tendency",
                          "Measuring Dispersion of data, Variance and Standard deviation,  Quartile Range",
                          "Regression Line",
                        ],
                      },
                      {
                        heading: "Working with real-world dataset",
                        desc: [
                          "Load the dataset using NumPy and Pandas",
                          "Read the dataset",
                          "Preprocess your data.",
                          "Apply different statistical analysis techniques to data",
                          "Handling of missing value",
                          "Create different charts for analysis and extract the information",
                        ],
                      },
                    ],
                  },
                  {
                    week: "9-10",
                    content: [
                      {
                        heading: "Live projects on",
                        desc: [
                          "Data Analytics for Sales data",
                          "Data Analytics for Student performance",
                          "Data Analytics for Stock Market",
                        ],
                      },
                    ],
                  },
                  {
                    week: "Graduation",
                    content: [
                      {
                        heading: "Graduation",
                        desc: [
                          "Congratulations! You’re now a Master in Data Analysis, and ready to kick-start your career.",
                          "You will receive a Deepiotics Academy certificate",
                          "You will receive relevant job alerts",
                        ],
                      },
                    ],
                  },
                ]}
                instructors={[
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg",
                    name: "Dr. Bhawna Nigam ",
                    designation: "Ph.D. in Machine Learning",
                    desc: "Sr. Data Scientist, an avid academician and researcher extensively worked with industry on  computer vision, deep learning and machine learning. She has 12 + years of Hands-on experience leveraging Artificial Intelligence to solve challenging business problems specially in Healthcare with 30+ Research Publications.",
                  },

                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Shraddha_gjhx9t.jpg",
                    name: "Ms. Shraddha Gupta",
                    designation: "M.Tech, Data Science & Forecasting",
                    desc: "Having 2+ years of experience in developing AI Based products and implementing Python. She likes to share her learning with Python enthusiasts. She has worked on healthcare and computer vision related products.",
                  },
                ]}
                whoDesc="If you are very analytical, love to play with data, and are curious, a career as a data analyst is for you."
                whoShouldJoin={[
                  {
                    name: "Beginner",
                    desc: "Who is a complete beginner and wants to become a Data Analyst.",
                  },
                  {
                    name: "IT Professionals",
                    desc: "Looking for a career transition to Data Scientists and Business Analysts.",
                  },
                  {
                    name: "Non-Computer Background",
                    desc: "Professionals are aiming to move ahead in their IT careers.",
                  },
                  {
                    name: "Entrepreneurs and business owners",
                    desc: "Who wants to use data analytics strategies for decision-making.",
                  },
                ]}
                faqs={[
                  {
                    question: "What happens if I can’t make a live session?",
                    answer:
                      "No problem, we record all live sessions and share them with the cohort.",
                  },
                  {
                    question:
                      "I work full-time, what is the expected time commitment?",
                    answer: "We estimate 6-10 hours per week.",
                  },
                  {
                    question: "Do I need to be a technical expert?",
                    answer:
                      "It's not required, but the course includes technical content and it might be a steep learning curve for participants who have absolutely no technical background.",
                  },
                  {
                    question: "How can I solve my coding doubts",
                    answer: "Weekly 1:1 Doubt clearing session will be held.",
                  },
                  {
                    question: "Can I take a break during the Course?",
                    answer:
                      "You can pause the course and resume after a break within two months.",
                  },
                ]}
                fees="INR 18000"
                startDate="1st May 2022"
                seats={10}
              />
            }
          />

          {/* Python For Researcher */}
          <Route
            exact
            path="/python-for-researcher"
            element={
              <CoursesPage
                heading="Python For Researcher"
                description="Learn Python for Research and get ready to use code notebooks to do fast experiments."
                aboutHeading="Start experiment with research experiment ready notebooks."
                bookUrl="https://calendly.com/deepiotics-academy/30-minute-python-for-researcher-free-live-demo"
                aboutContent={[
                  "A precise Twelve-week course created by an experienced team of  Research Scientists. Python is a powerful general-purpose programming language that is quickly becoming a popular research tool. Python can be utilized at numerous stages of the research process and can handle most day-to-day research jobs. Python can save researchers a lot of time and effort by eliminating the need to use many software applications to complete different tasks.",

                  "This course consists of coding notebooks ready for your data to experiment which helps to run your experiment by just changing the data file.  This course begins with a review of Python fundamentals before moving on to tools often used in research contexts. You will get to exercise your new Python abilities with case studies chosen for their scientific breadth and coverage of different Python features, using a combination of a guided introduction and more independent in-depth research.",

                  "So, what are you waiting for?  Learn Python for research experiments in a fun and practical method. Complete your data experiment by just changing data files.",
                ]}
                courseProperty={{
                  duration: "12 weeks",
                  classSchedule: "Morning / Evening / Weekend Classes",
                  commitment: "4-10 hr/ week",
                  language: "Hindi , English",
                  teachingMode:
                    "Online, Hands-on, Interactive Classe, 1:1 Doubt Solving",
                  certification: "On successful completion of Course.",
                  courseMaterial: "Notebooks, PPTs, Cheatsheet",
                  fees: "30000 Rs./-",
                  introVideo:
                    "https://www.youtube.com/watch?v=hEgO047GxaQ&ab_channel=Telusko",
                }}
                whyHeading="Why to choose Python for Research"
                whyDescription="Python is rapidly gaining popularity as a research tool. Python may be used at various phases of the research process and can perform most routine research works"
                whyItems={[
                  "Python is alone sufficient to stand out in the majority of available programmers and profession choices.",
                  "An entry-level Research Analyst with less than 1-year experience can earn an average total compensation of $60,000",
                  "It gives excellent career opportunities, especially in India, because of the strong demand-supply ratio.",
                  "From NASA & Google to your neighbourhood startup everyone needs a Research Scientist.",
                ]}
                liveProjects={[
                  {
                    name: "Diabetes Prediction",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "House Pricing Prediction",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Walmart Sales Forecasting",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                ]}
                industryValidatedCurriculam={[
                  {
                    week: "1-3",
                    content: [
                      {
                        heading: "Introduction",
                        desc: [
                          "Introduction to python for research",
                          "Python Research Prospects",
                          "Various use cases,  Learn Anaconda Python Installation",
                          "Working with Jupyter Notebook",
                          "Working with Cloud",
                        ],
                      },
                      {
                        heading: "Python Programming",
                        desc: [
                          "Concept of Variable, Input, and Output",
                          "Working with conditional statements  - if,elif, else",
                          "While Loop and For Loop",
                          "Python Data Structure - List, Tuple, Set, Dictionary",
                          "Function and Modules",
                          "Working with Libraries",
                        ],
                      },
                    ],
                  },

                  {
                    week: "4-6",
                    content: [
                      {
                        heading: "Python Research Tool",
                        desc: [
                          "Scientific Computation using Numpy, Importing Data using Pandas",
                          "Working with Pandas DataFrame, Deal with CSV file, Data analysis using pandas",
                          "Use matplotlib with data, Create visualization of raw data.",
                          "Create a Bar chart, Pie chart, Histogram, and Scatter plot using Matplotlib",
                          "Read and extract the information from charts, Working with Scipy, Computation using Scipy, Introduction to scikit-learn",
                          "$ Notebook for Scientific Computation, Dealing with CSV file, Data Plotting",
                        ],
                      },
                      {
                        heading: "Statistical Data Visualization",
                        desc: [
                          "Introduction to Visualization and  Statistics techniques, Descriptive Analysis",
                          "Descriptive statistics - Min, Max,  Mean, Median, Mode, Standard deviation",
                          "Handling missing values and Reliability Analysis, Correlational data analysis, Histogram, Scatter Plot",
                          "$ Notebook for Data visualization",
                        ],
                      },
                    ],
                  },
                  {
                    week: "7-9",
                    content: [
                      {
                        heading: "Statistical Methods",
                        desc: [
                          "Regression Analysis and Hypothesis Testing",
                          "Correlation and Covariance",
                          "Analysis of Variance and P-Value",
                          "Chi-square testing",
                          "RMSE, R square, Error Analysis",
                          "$ Notebook for statistical Analysis,  Error Analysis",
                        ],
                      },
                      {
                        heading: "Statistical Learning",
                        desc: [
                          "Exploration of statistical learning using the scikit-learn library.",
                          "Understanding of supervised and unsupervised learning concepts.",
                          "Linear Regression, Logistic Regression, Decision tree, Random Forest",
                          "$ Notebook for Linear Regression, Logistic Regression, Decision Tree, for Random  Forest",
                        ],
                      },
                    ],
                  },
                  {
                    week: "10",
                    content: [
                      {
                        heading: "Case Studies",
                        desc: [
                          "Work with a variety of case studies from a variety of disciplines to put your Python research skills to the test.",
                          "Classification of news is fake or not",
                          "Classify the quality of wine",
                        ],
                      },
                    ],
                  },
                  {
                    week: "11-12",
                    content: [
                      {
                        heading: "Live Projects On",
                        desc: [],
                      },
                      {
                        heading: "Diabetes Prediction",
                        desc: [
                          "Importing Dataset",
                          "Data Analysis",
                          "Data Preprocessing",
                          "Model Creation",
                          "Model Evaluation",
                        ],
                      },
                      {
                        heading: "House Pricing Prediction",
                        desc: [
                          "Importing Dataset",
                          "Data Analysis",
                          "Data Preprocessing",
                          "Model Creation",
                          "Model Evaluation",
                        ],
                      },
                      {
                        heading: "Walmart Sales Forecasting",
                        desc: [
                          "Importing Dataset",
                          "Data Analysis",
                          "Data Preprocessing",
                          "Model Creation",
                          "Model Evaluation",
                        ],
                      },
                    ],
                  },
                  {
                    week: "Graduation",
                    content: [
                      {
                        heading: "Graduation",
                        desc: [
                          "Congratulations! You are now well versed  in Python for Research, and ready to kick-start your career as Research Scientist.",
                          "You will receive a Deepiotics Academy certificate",
                          "You will receive relevant job alerts",
                        ],
                      },
                    ],
                  },
                ]}
                instructors={[
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg",
                    name: "Dr. Bhawna Nigam ",
                    designation: "Ph.D. in Machine Learning",
                    desc: "Sr. Data Scientist, an avid academician and researcher extensively worked with industry on  computer vision, deep learning and machine learning. She has 12 + years of Hands-on experience leveraging Artificial Intelligence to solve challenging business problems specially in Healthcare with 30+ Research Publications.",
                  },
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Shraddha_gjhx9t.jpg",
                    name: "Ms. Shraddha Gupta",
                    designation: "M.Tech, Data Science & Forecasting",
                    desc: "Having 2+ years of experience in developing AI Based products and implementing Python. She likes to share her learning with Python enthusiasts. She has worked on healthcare and computer vision related products.",
                  },
                ]}
                whoDesc="This course is ideal for anyone who wants to advance their research career or who is interested in learning more about research"
                whoShouldJoin={[
                  {
                    name: "Graduate/ Master/ Ph.D. Students",
                    desc: "Who wants to experiment on data to  publish a Research paper.",
                  },
                  {
                    name: "Business",
                    desc: "Who wants to set up the R&D lab & Positioning in market as R & D giant.",
                  },
                  {
                    name: "Industry Experts",
                    desc: "Who want to start their career as Research Scientist.",
                  },
                  {
                    name: "Researcher/ Academician",
                    desc: "Anyone wants to learn coding for Research experiments.",
                  },
                ]}
                faqs={[
                  {
                    question: "What happens if I can’t make a live session?",
                    answer:
                      "No problem, we record all live sessions and share them with the cohort.",
                  },
                  {
                    question:
                      "I work full-time, what is the expected time commitment?",
                    answer: "We estimate 6-10 hours per week.",
                  },
                  {
                    question: "Do I need to be a technical expert?",
                    answer:
                      "It's not required, but the course includes technical content and it might be a steep learning curve for participants who have absolutely no technical background.",
                  },
                  {
                    question: "How can I solve my coding doubts",
                    answer: "Weekly 1:1 Doubt clearing session will be held.",
                  },
                  {
                    question: "Can I take a break during the Course?",
                    answer:
                      "You can pause the course and resume after a break within two months.",
                  },
                ]}
                fees="INR 30000"
                startDate="1st May 2022"
                seats={10}
              />
            }
          />

          {/* Research Paper Writing */}
          <Route
            exact
            path="/research-paper-writing"
            element={
              <CoursesPage
                heading="Research Paper Writing"
                description="Write your Research Paper with experienced Researchers"
                aboutHeading="Start Research writing Today!"
                bookUrl="https://calendly.com/deepiotics-academy/30-minute-research-paper-writing-free-live-demo-clone"
                aboutContent={[
                  "This Course is for students who want to learn how to write a research paper at a fast pace with the help of tools. The process is broken down day-by-day to achieve milestones of your paper. Learn the tools and techniques to write research papers in a much easier way. Learn how to find a topic, do research, and organize your ideas. At the end of course you will have a finished paper. Spend less time struggling with your paper, and more time on the beach.",

                  "So, what are you waiting for? Start your paper writing journey.",
                ]}
                courseProperty={{
                  duration: "7 weeks",
                  classSchedule: "Morning / Evening / Weekend Classes",
                  commitment: "4-10 hr/ week",
                  language: "Hindi , English",
                  teachingMode:
                    "Hands-on, applied projects, online  Interactive  labs, 1:1 Doubt Solving",
                  certification: "On successful completion of Course.",
                  courseMaterial: "Notebooks, PPTs, Cheatsheet",
                  fees: "30000 Rs. /-",
                  introVideo:
                    "https://www.youtube.com/watch?v=hEgO047GxaQ&ab_channel=Telusko",
                }}
                whyHeading="Why to write Research Paper"
                whyDescription="Research paper is the way of passing your knowledge & findings to scientists working in the same or related fields. It develops the research mindset to help in a career as a Research Scientist. It helps to build your academic profile."
                whyItems={[
                  "Research Mindset enables you to present your own evaluation and finding for your interested topic. ",
                  "It builds your academic profile for applying to the senior positions.",
                  "Helps to build your professional connection with other researchers and authors.",
                  "It helps to move your first step as Research Assistant/ Research Scientist.",
                ]}
                whatYouWillGet={true}
                liveProjects={[
                  {
                    name: "Research Paper",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Templates",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Free tool",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                ]}
                industryValidatedCurriculam={[
                  {
                    week: "1",
                    content: [
                      {
                        heading: "Introduction",
                        desc: [
                          "Why to Publish the paper.",
                          "Benefits for Research paper in your profile",
                          "SMART Goal Setting",
                          "Type of paper",
                          "Scan articles quickly.",
                        ],
                      },
                      {
                        heading: "Paper Structure & Framework",
                        desc: [
                          "Understanding of Paper structure",
                          "Frame your Paper structure",
                          "Ways to introduce novelty",
                        ],
                      },
                      {
                        heading: "Brainstorming Session",
                        desc: ["Frame your paper from title to References"],
                      },
                    ],
                  },

                  {
                    week: "2-3",
                    content: [
                      {
                        heading: "Titles and abstracts",
                        desc: [
                          "Design a Perfect, relevant Title for Paper",
                          "Abstract templates that you can apply directly to your own writing",
                          "what to avoid when writing titles and abstracts.",
                          "How to construct an effective title",
                        ],
                      },
                      {
                        heading: "Brainstorming Session",
                        desc: [
                          "Finalize your Paper Title",
                          "Abstract Template",
                        ],
                      },
                      {
                        heading: "Systematic Literature Review",
                        desc: [
                          "Searching relevant Research Paper",
                          "Tips & Tools to get research paper free.",
                          "Ways to write effective Literature Review",
                        ],
                      },

                      {
                        heading: "Brainstorming Session",
                        desc: [
                          "Templates for Literature Review",
                          "Discuss and Complete the LR for your paper ",
                        ],
                      },
                    ],
                  },
                  {
                    week: "4-5",
                    content: [
                      {
                        heading: "Effective figure creation",
                        desc: [
                          "Create clear and engaging figures for your paper.",
                          "Learn easy online tools for creating high quality paper.",
                          "Pen down to draw  figures for paper.",
                          "Tips for clear and informative  figures.",
                          "Tool training for high quality figure creation.",
                        ],
                      },
                      {
                        heading: "Methods & System",
                        desc: [
                          "Ingredients of Method/ Design/ Methodology section",
                          "Dataset Representation",
                          "Descriptive statistics for Data",
                          "Algorithm representation",
                          "Design the diagram with online free tools",
                        ],
                      },
                    ],
                  },
                  {
                    week: "6",
                    content: [
                      {
                        heading: "Experiment Design",
                        desc: [
                          "Dataset Representation",
                          "Descriptive statistics for Data",
                          "Creating effective charts",
                          "Highlighting the findings",
                          "Checklist",
                        ],
                      },
                    ],
                  },
                  {
                    week: "7",
                    content: [
                      {
                        heading: "Reference Writing",
                        desc: ["Free software to create perfect citations"],
                      },
                      {
                        heading:
                          "Plagiarism checking & Removal Techniques with tools",
                        desc: [],
                      },
                      {
                        heading:
                          "Find conference and journal for Paper in 5 minutes",
                        desc: [],
                      },
                      {
                        heading:
                          "Convert your paper in a selected conference or journal format in 10 mins with tools.",
                        desc: [],
                      },
                      {
                        heading:
                          "TEMPLATES for every part of your research paper.",
                        desc: [],
                      },
                    ],
                  },

                  {
                    week: "Graduation",
                    content: [
                      {
                        heading: "Graduation",
                        desc: [
                          "Congratulations! Your Paper is ready to publish.",
                        ],
                      },
                    ],
                  },
                ]}
                instructors={[
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg",
                    name: "Dr. Bhawna Nigam ",
                    designation: "Ph.D. in Machine Learning",
                    desc: "Sr. Data Scientist, an avid academician and researcher extensively worked with industry on  computer vision, deep learning and machine learning. She has 12 + years of Hands-on experience leveraging Artificial Intelligence to solve challenging business problems specially in Healthcare with 30+ Research Publications.",
                  },
                ]}
                whoDesc=""
                whoShouldJoin={[
                  {
                    name: "Graduate/ Master/ Ph.D. Students",
                    desc: "Who wants to experiment on data to  publish a Research paper.",
                  },
                  {
                    name: "Business",
                    desc: "Who wants to set up the R&D lab & Positioning in the market as Research Leader .",
                  },
                  {
                    name: "Industry Experts",
                    desc: "Researchers in industry who are expected to write Research papers.",
                  },
                  {
                    name: "Researcher/ Academician",
                    desc: "Anyone wants to learn coding for Research experiments.",
                  },
                ]}
                faqs={[
                  {
                    question: "What happens if I can’t make a live session?",
                    answer:
                      "No problem, we record all live sessions and share them with the cohort.",
                  },
                  {
                    question:
                      "I work full-time, what is the expected time commitment?",
                    answer: "We estimate 6-10 hours per week.",
                  },
                  {
                    question: "Do I need to be a technical expert?",
                    answer:
                      "It's not required, but the course includes technical content and it might be a steep learning curve for participants who have absolutely no technical background.",
                  },
                  {
                    question: "How can I solve my coding doubts",
                    answer: "Weekly 1:1 Doubt clearing session will be held.",
                  },
                  {
                    question: "Can I take a break during the Course?",
                    answer:
                      "You can pause the course and resume after a break within two months.",
                  },
                ]}
                fees="INR 30000"
                startDate="1st June 2022"
                seats={10}
              />
            }
          />

          {/* AI For Healthcare Professional */}
          <Route
            exact
            path="/ai-for-healthcare-professional"
            element={
              <CoursesPage
                heading="AI For Healthcare Professional"
                description="Learn Artificial Intelligence to become Healthcare data scientist."
                aboutHeading="Start your journey in Healthcare Data Scientist."
                bookUrl="https://calendly.com/deepiotics-academy/30-minute-ai-for-healthcare-free-live-demo"
                aboutContent={[
                  "A rigorous Eighteen-week course created by an experienced team of international AI experts. Artificial Intelligence (AI) has transformed industries around the world, and it has the potential to transform healthcare as well. Consider how Artificial Intelligence (AI) could change patient care and diagnoses by analyzing data from clinic visits, prescriptions, lab tests, and treatments, as well as data from outside the health system, such as social media and Internet search activity logs that contain vital health information.",

                  "In this course, we will talk about existing and future AI uses in healthcare with the objective of learning how to incorporate AI technologies into the Healthcare system.",

                  "So, what are you waiting for?  Learn Artificial Intelligence for Healthcare in a fun and practical method that can help you enhance your career and expertise.",
                ]}
                courseProperty={{
                  duration: "18 Weeks",
                  classSchedule: "Morning / Evening / Weekend Classes",
                  commitment: "4-6 hr/ week",
                  language: "Hindi , English",
                  teachingMode:
                    "Online, Hands-on, Interactive Classe, 1:1 Doubt Solving",
                  certification: "On successful completion of Course.",
                  courseMaterial: "Notebooks, PPTs, Cheatsheet",
                  fees: "150000 Rs. /-",
                  introVideo:
                    "https://www.youtube.com/watch?v=hEgO047GxaQ&ab_channel=Telusko",
                }}
                whyHeading="Why to choose Artificial Intelligence for Healthcare"
                whyDescription="AI is now one of the fastest-growing employment technologies. Engineers who specialize in artificial intelligence are also in high demand these days. Studying AI brings up a world of potential to produce cutting-edge technology in a variety of areas, with every organization trying to introduce Artificial Intelligence in their domain."
                whyItems={[
                  "Learning AI will allow you to challenge current working methods and alter your perception of most things.",
                  "In healthcare, AI has enormous potential. AI is becoming a bigger component of our healthcare ecosystem, just like it is in our daily lives.",
                  "AI will produce many new jobs, including highly skilled, management, and even entry-level and low-skilled positions",
                  "In healthcare, AI refers to a set of technologies that allow collect, interpret, act, and learn in order to perform administrative and clinical tasks.",
                ]}
                liveProjects={[
                  {
                    name: "Early detection of Cancer using DNA Sequencing",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Heart Disease classification",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Breast Cancer Prediction",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },

                  {
                    name: "Automatic Covid detection for  XRay",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329359/accounting_xjwvtx.png",
                  },
                  {
                    name: "Dental Health Check using Panoramic Dental XRay",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Skin Disease Detection",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329312/rock-paper-scissors_wbktkj.png",
                  },
                  {
                    name: "Diabetes Prediction with Multilayer Perceptron",
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653329252/random_rds6wv.png",
                  },
                ]}
                industryValidatedCurriculam={[
                  {
                    week: "1-3",
                    content: [
                      {
                        heading: "Introduction",
                        desc: [
                          "Introduction to Artificial Intelligence",
                          "Use of AI in healthcare.",
                          "Learn Anaconda Python Installation",
                          "Working with Jupyter Notebook",
                          "Working with Cloud.",
                        ],
                      },
                      {
                        heading: "Fast track review of Python Libraries",
                        desc: [
                          "Working with NumPy",
                          "Working with pandas",
                          "Data Analysis with pandas",
                          "Data Preprocessing with pandas",
                          "Data Visualization with Matplotlib",
                        ],
                      },
                    ],
                  },

                  {
                    week: "4-6",
                    content: [
                      {
                        heading:
                          "Fundamentals of AI and Machine Learning for Healthcare",
                        desc: [
                          "Introduction to Machine Learning",
                          "Why machine learning in healthcare",
                          "Workflow of machine learning",
                          "Working with algorithms",
                          "Understanding of evaluation metrics.",
                        ],
                      },
                      {
                        heading: "Introduction to Deep Learning",
                        desc: [
                          "Introducing the concept of deep learning.",
                          "Learn about neural network and artificial neural network",
                          "Working with different activation function",
                          "Working with keras and TensorFlow.",
                        ],
                      },
                    ],
                  },
                  {
                    week: "7-9",
                    content: [
                      {
                        heading:
                          "Early detection of Cancer using DNA Sequencing",
                        desc: [
                          "Introduction to DNA Classification,",
                          "Importing the dataset,  Data preprocessing",
                          "Generating a DNA sequence",
                          "Splitting the data into training and testing",
                          "Evaluation, Scoring and Metrics",
                        ],
                      },
                      {
                        heading: "Heart Disease classification",
                        desc: [
                          "Introduction to the heart disease",
                          "Importing library & dataset",
                          "Data analysis and handling missing data",
                          "Data scaling and visualization",
                          "Splitting the data into training and testing",
                          "Model evaluation",
                        ],
                      },
                    ],
                  },
                  {
                    week: "10-12",
                    content: [
                      {
                        heading: "Breast Cancer Prediction",
                        desc: [
                          "Introduction to the Breast Cancer",
                          "Importing library and dataset",
                          "Data Preprocessing",
                          "Data visualization",
                          "Understanding of Algorithm",
                          "Model Training, Model evaluation",
                        ],
                      },
                      {
                        heading: "Automatic Covid detection for XRay",
                        desc: [
                          "Covid detection",
                          "Understanding of the dataset",
                          "Data Preprocessing",
                          "Data Classification",
                          "Model Training and  Evaluation",
                        ],
                      },
                    ],
                  },
                  {
                    week: "13-15",
                    content: [
                      {
                        heading:
                          "Dental Health Check using Panoramic Dental XRay",
                        desc: [
                          "Introduction to the project",
                          "Importing library and data",
                          "Exploratory Analysis, Data scaling",
                          "Data Visualization, Model Training",
                          "Model Evaluation",
                        ],
                      },
                      {
                        heading: "Skin Disease Detection",
                        desc: [
                          "Introduction to Skin Disease",
                          "Understanding of dataset",
                          "Data Preprocessing, Data Visualization",
                          "Data Classification, Model Training",
                          "Model Evaluation",
                        ],
                      },
                      {
                        heading:
                          "Diabetes Prediction with Multilayer Perceptron",
                        desc: [
                          "Introduction to the project",
                          "Importing libraries and data",
                          "Data Visualization",
                          "Handling missing data",
                          "Data standardization",
                          "Model Training",
                          "Model Evaluation",
                        ],
                      },
                    ],
                  },
                  {
                    week: "16-18",
                    content: [
                      {
                        heading: "Model Deployment",
                        desc: [
                          "Model retaining",
                          "Model version control",
                          "Introduction to API development",
                          "Flask API Development",
                          "Deploy AI model on web and APP.",
                          "Learn how to version your code with Git and share it with your team",
                          "Issue and project tracking using JIRA.",
                        ],
                      },
                    ],
                  },

                  {
                    week: "Graduation",
                    content: [
                      {
                        heading: "Graduation",
                        desc: [
                          "Congratulations! You’re now a Master  in Artificial Intelligence for Healthcare, and ready to kick-start your career",
                          "You will receive a Deepiotics Academy certificate.",
                          "You will receive relevant job alerts.",
                        ],
                      },
                    ],
                  },
                ]}
                instructors={[
                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg",
                    name: "Dr. Bhawna Nigam ",
                    designation: "Ph.D. in Machine Learning",
                    desc: "Sr. Data Scientist, an avid academician and researcher extensively worked with industry on  computer vision, deep learning and machine learning. She has 12 + years of Hands-on experience leveraging Artificial Intelligence to solve challenging business problems specially in Healthcare with 30+ Research Publications.",
                  },

                  {
                    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Vijay_anyi7q.jpg",
                    name: "Mr. Vijay Birchha ",
                    designation: "M.E. Software Engineering",
                    desc: "Having 10+ years of experience in Data Science and Analytics with product architecture design and delivery. Working at Deepiotics with various product and service based companies. Having an experience of 5+ years in educating people and helping them to make a career transition. He is pursuing Ph.D. in Early Cancer Detection using Deep Learning.",
                  },
                ]}
                whoDesc="This course is the perfect choice for those who want to learn AI and have no prior experience and also for those who want to expand their career with AI."
                whoShouldJoin={[
                  {
                    name: "Beginner",
                    desc: "Desire to work in Healthcare industry in AI roles.",
                  },
                  {
                    name: "Technology leaders",
                    desc: "Who want to expand their career choices as Data scientist in Healthcare.",
                  },
                  {
                    name: "Healthcare Professionals ",
                    desc: "Who wants to shift careers by applying AI  in Healthcare  Doctors, Dentistry, Radiologists, Physician,cardiologist.",
                  },
                  {
                    name: "Healthcare Business",
                    desc: "Who wants to use AI use case in their product",
                  },
                ]}
                faqs={[
                  {
                    question: "What happens if I can’t make a live session?",
                    answer:
                      "No problem, we record all live sessions and share them with the cohort.",
                  },
                  {
                    question:
                      "I work full-time, what is the expected time commitment?",
                    answer: "We estimate 6-10 hours per week.",
                  },
                  {
                    question: "Do I need to be a technical expert?",
                    answer:
                      "It's not required, but the course includes technical content and it might be a steep learning curve for participants who have absolutely no technical background.",
                  },
                  {
                    question: "How can I solve my coding doubts",
                    answer: "Weekly 1:1 Doubt clearing session will be held.",
                  },
                  {
                    question: "Can I take a break during the Course?",
                    answer:
                      "You can pause the course and resume after a break within two months.",
                  },
                ]}
                fees="INR 150000"
                startDate="1st June 2022"
                seats={10}
              />
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
