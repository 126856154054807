import React from "react";
import { Parallax } from "react-parallax";

const DescriptionItem = ({ title, text }) => {
  return (
    <div>
      <div className=" font-bold">{title}</div>
      <div className="text-sm">{text}</div>
    </div>
  );
};


const Program = (props) => {

  return (

    <div className="flex flex-col items-center md:justify-between md:flex-row-reverse">
      <div className="w-screen z-0">
        <Parallax
          blur={1}
          bgImage="https://res.cloudinary.com/insight-byte/image/upload/e_brightness:-24/v1650000651/coding-man_1_oxzhdm.png"
          bgImageAlt="the cat"
          strength={300}
        >
          <div className=" p-20 flex flex-col justify-between h-[400px] bg-black bg-opacity-20">
            <div>
              <div className="text-white text-4xl font-bold">
                {props.heading}
              </div>
              <div className="mt-5 w-1/4 sm:w-1/3 lg:1/6 text-white font-semibold tracking-wide">
                {props.description}
              </div>
            </div>
            <a href={props.bookUrl} target="_blank"><div className="py-2 px-4 hover:bg-[#de1637] transition-all cursor-pointer hover:scale-105 rounded-md text-white font-semibold bg-[#EA3453] w-fit" >
              Book Free Live Demo
            </div></a>
          </div>
        </Parallax>

        <div className="px-6 md:px-20 py-12 bg-center bg-cover">
          <div className="text-lg text-primary">About</div>
          <div className="md:text-2xl lg:text-3xl w-[50%] text-primary">{props.aboutHeading}</div>
          <div className="mt-6 md:w-[60%]">
            {
              props.aboutContent.map((value, index) => {
                return (
                  <>
                    <p key={index}>{value}</p>
                    <br />
                  </>
                );
              })
            }
          </div>
        </div>
      </div>

      <div className="bg-yellow-400 space-y-4 shadow-md  p-6 md:mr-12 md:absolute md:w-[200px] lg:w-[370px] ">
        <DescriptionItem title="Program Duration" text={props.courseProperty.duration} />
        <DescriptionItem
          title="Class Schedule"
          text={props.courseProperty.classSchedule}
        />
        <DescriptionItem title="Commitment" text={props.courseProperty.commitment} />
        <DescriptionItem title="Language" text={props.courseProperty.language} />
        <DescriptionItem
          title="Teaching Mode"
          text={props.courseProperty.teachingMode}
        />
        <DescriptionItem
          title="Certification"
          text={props.courseProperty.certification}
        />
        <DescriptionItem
          title="Course Material"
          text={props.courseProperty.courseMaterial}
        />
        <DescriptionItem title="Fees" text={props.courseProperty.fees} />
        <div className="w-full">
          <div className="font-bold">Intro Video</div>
          <div className="text-[#EA3453]  font-semibold">
            <p className="">
              <a href={props.courseProperty.introVideo}>
                Youtube (Link)
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
