import React from 'react'

function Advisor(props) {
  return (
    <div className='flex flex-col justify-center items-center p-3 w-48 md:w-56 advisor'>
      <div className="w-[40%] h-[40%] md:w-[100%] md:h-[100%]">
        <img src={props.src} className="rounded-full w-full h-full object-cover" />
      </div>
      <p className='font-semibold whitespace-nowrap'>{props.name}</p>
      <p className=' text-center md:text-base text-sm md:text-justify'>{props.designation}</p>
    </div>
  )
}

export default Advisor