import React from 'react'

function WhoCard(props) {
  return (
    <div data-aos="flip-left" className='w-full my-4 md:my-0 md:w-1/6 lg:w-1/6 mx-8 bg-white text-black rounded-3xl p-5'>
      <span className="bg-[#EA3453] font-semibold h-10 w-10 rounded-full flex justify-center items-center text-white relative -top-10 ">{props.no}</span>
        <div className="-mt-5">
        <p className='font-semibold'>{props.name}</p>
        <p>{props.desc}</p>
        </div>
    </div>
  )
}

export default WhoCard