import React from 'react'
import WhoCard from './WhoCard'

function WhyShouldJoin(props) {

  const wsj = props.whoShouldJoin 

  return (
    <div className='bg-[#003953] text-white flex flex-col justify-center items-center py-4'>
      <div className="w-3/4 my-2">
        <h1 className='text-2xl font-semibold'>Who Should Join?</h1>
        <p>{props.whoDesc}</p>
      </div>

      <div className="flex flex-wrap md:justify-center py-6">
        {
          wsj.map((value, index) => {
            return (<WhoCard name={value.name} desc={value.desc} no={index + 1} key={index} />)
          })
        }
      </div>
    </div>
  )
}

export default WhyShouldJoin