import React, { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import axios from "axios";


function Footer() {

  const [user, setUser] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [subscriber, setSubscriber] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setSubscriber({
      ...subscriber,
      [name]: value,
    });
  };


  const registerSubs = () => {
    const { email } = subscriber;
    if (email) {
      axios
        .post('https://deepioticsacademy.herokuapp.com/subscriber', subscriber)
        .then((res) => console.log(res));
    } else {
      alert("Invalid Input");
    }

    setSubscriber({
      email: "",
    });
  };


  const register = () => {
    const { name, email, message } = user;
    if (
      name &&
      email &&
      message
    ) {
      axios
        .post('https://deepioticsacademy.herokuapp.com/contact', user)
        .then((res) => console.log(res));
      alert("Your Message is Registered Succesfully.")
    } else {
      alert("Invalid Input");
    }

    setUser({
      name: "",
      message: "",
      email: "",
    });
  };

  return (
    <div>
      <div className="bg-[#111327] p-12 pb-0 pt-12 md:w-full items-center h-full footerTop flex md:flex-row flex-col justify-around md:items-start">
        <div className="flex gap-6 pb-5 w-[95%] md:w-full">
          <input type="text" name="email" onChange={handleChange2} placeholder="newsletter@gmail.com" className="rounded-3xl subsInput" />
          <a href="" className="bg-[#EA3453] text-white rounded-3xl subsSubmit"><button onClick={registerSubs}>Subscribe Now</button></a>
        </div>
        <div className="flex gap-6 pb-5">
          <FaInstagram className="text-2xl cursor-pointer text-white hover:text-yellow-600" />
          <FaTwitter className="text-2xl cursor-pointer text-white hover:text-blue-600" />
          <FaLinkedin className="text-2xl cursor-pointer text-white hover:text-blue-600" />
          <FaYoutube className="text-2xl cursor-pointer text-white hover:text-red-600" />
        </div>
      </div>
      <div className="bg-white w-[90%] m-auto h-[1px]">
      {/* <hr className="border-[1px] px-5"/> */}

      </div>
      <div className="bg-[#111327] w-full h-full flex md:flex-row flex-col justify-around items-start p-12 pt-8 pb-0 ">
        <div className="p-4">
          <ul>
            <p className="text-white font-bold text-2xl pb-4">Business</p>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
             <a href="/training"> Corporate Training</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
            <a href="/sponsor"> Sponsor a Candidate</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
            <a href="/hire">Hire from us</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
            <a href="/partner">Partner with us</a>
            </li>
          </ul>
        </div>
        <div className="p-4">
          <ul>
            <p className="text-white font-bold text-2xl pb-4">Courses</p>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
             <a href=" /courses"> Our Courses</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Become a Instructor
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Suggest a Course
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Give us a Feedback
            </li>
          </ul>
        </div>
        <div className="p-4">
          <ul>
            <p className="text-white font-bold text-2xl pb-4">About Us</p>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
             <a href="/about"> Deepiotics</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Team
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Advisor
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              <a href="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654105749/Privacy_Policy.rtf_ttxhz7.pdf" target="_blank"> Privacy Policy</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
            <a href="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654105703/Terms_and_Conditon.docx_psxpln.pdf" target="_blank">Terms And Conditions</a>
            </li>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
            <a href="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654105748/Refund_Policy.rtf_lcnvvg.pdf" target="_blank"> Refund Policy</a>
            </li>
          </ul>
        </div>

        <div className="p-4">
          <ul>
            <p className="text-white font-bold text-2xl pb-4">Contact Us</p>
            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Deepiotics Academy <br />
              A-88, Vaibhav Nager, Indore, India
            </li>

            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
            </li>

            <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
              Email: <br></br>
              academy@deepiotics.com
            </li>

          </ul>
        </div>

        <div className="p-4">
          <ul>
            <p className="text-white font-bold text-2xl pb-4">Leave a Message</p>
            <div method="POST">
              <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                <input
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={handleChange}
                  placeholder="Name" 
                  className="rounded-3xl formInput" />
              </li>
              <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="rounded-3xl formInput" />
              </li>
              <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                <input
                  type="text"
                  name="message"
                  value={user.message}
                  onChange={handleChange}
                  placeholder="Message"
                  className="rounded-3xl formInput" />
              </li>
              <div className="flex justify-center"><button className="bg-[#EA3453] rounded-3xl  text-white hover:text-blue-600 p-1 w-24 mt-1" onClick={register}>Submit</button></div>
              
            </div>
          </ul>
        </div>
        <div className="p-4"></div>
      </div>
      <div className=" bg-[#111327] text-white w-full h-full flex flex-col justify-center items-center text-center  p-4">
        <h1 className="font-semibold">
          Designed And Developed By <a href="http://archlabs.co.in/" target="_blank"><img src="https://res.cloudinary.com/unesco-admin/image/upload/v1655819011/LOGO1_ztvkiy.png" className="w-[28%] inline" alt="" /></a>
        </h1>
      </div>
    </div>
  );
}

export default Footer;