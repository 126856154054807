import React, { useState } from 'react'

const views = [
  "A precise Seven-week course has been designed by an experienced team of international AI experts & Data Scientist. Python is the most widely used programming language and the preferred language for Machine Learning, Data Science, Artificial Intelligence, Automation, Data Analysis and Research experiments in the world. You will need an excellent knowledge of Python to get those high-paying positions, which you will get through this course.",

  "A rigorous Ten-week course created by an experienced team of Data Analysts.  Data analytics capabilities are just too critical for staying competitive. It has gained popularity from a few industries to nearly every industry and market. You'll learn how to plan data analysis solutions and data analytic processes along with how to apply statistics and predictive analytics to real-world datasets. Also how to work with SQL databases and build data visualizations.",

  "A precise Twelve-week course created by an experienced team of  Research Scientists. Python is a powerful general-purpose programming language that is quickly becoming a popular research tool. Python can be utilized at numerous stages of the research process and can handle most day-to-day research jobs. Python can save researchers a lot of time and effort by eliminating the need to use many software applications to complete different tasks.",

  "This Course is for students who want to learn how to write a research paper at a fast pace with the help of tools. The process is broken down day-by-day to achieve milestones of your paper. Learn the tools and techniques to write research papers in a much easier way. Learn how to find a topic, do research, and organize your ideas. At the end of course you will have a finished paper. Spend less time struggling with your paper, and more time on the beach.",

  "A rigorous eight-week course has been created by an experienced team of  Data Scientists. Data science is an interdisciplinary discipline that uses scientific methods, procedures, algorithms and system to extract information and knowledge from noisy, structured, and unstructured data, as well as to apply that knowledge and actionable insights across a variety of application domains.This Data Science certification provides in-depth instruction on the most in-demand Data Science and Machine Learning skills, as well as hands-on experience with major tools and technologies. To get such high-paying positions, you will need a strong understanding of Data Science, which you will learn in this certification.",

  "A rigorous Eighteen-week course created by an experienced team of international AI experts. Artificial Intelligence (AI) has transformed industries around the world, and it has the potential to transform healthcare as well. Consider how Artificial Intelligence (AI) could change patient care and diagnoses by analyzing data from clinic visits, prescriptions, lab tests, and treatments, as well as data from outside the health system, such as social media and Internet search activity logs that contain vital health information.",
]

function Courses() {
  const [view, setView] = useState(0);
  const [url, setUrl] = useState("python-for-everyone");

  return (
    <div className='p-10 bg-[#003953]' id='courses'>
      <h1 className='text-3xl text-white text-center font-bold h-[10%]'>Courses</h1>

      <div className="flex flex-col md:flex-row justify-around items-center h-[90%]">

        <ul className='my-10 md:my-20'>
          <li onClick={() => { setView(0); setUrl("python-for-everyone") }} className={`${view === 0 ? 'text-white' : 'text-[#ffffff81]'} hover:text-white text-2xl cursor-pointer my-4 font-semibold flex items-center`}> <div className="bg-[#EA3453] mx-2 w-2 h-9"></div> Python for Everyone</li>
          <li onClick={() => { setView(1); setUrl("python-for-data-analysis") }} className={`${view === 1 ? 'text-white' : 'text-[#ffffff81]'} hover:text-white text-2xl cursor-pointer my-4 font-semibold flex items-center`}> <div className="bg-[#EA3453] mx-2 w-2 h-9"></div> Certification in Data Analytics</li>
          <li onClick={() => { setView(2); setUrl("python-for-researcher") }} className={`${view === 2 ? 'text-white' : 'text-[#ffffff81]'} hover:text-white text-2xl cursor-pointer my-4 font-semibold flex items-center`}> <div className="bg-[#EA3453] mx-2 w-2 h-9"></div> Python for Researcher</li>
          <li onClick={() => { setView(3); setUrl("research-paper-writing") }} className={`${view === 3 ? 'text-white' : 'text-[#ffffff81]'} hover:text-white text-2xl cursor-pointer my-4 font-semibold flex items-center`}> <div className="bg-[#EA3453] mx-2 w-2 h-9"></div> Research Paper Writing</li>
          <li onClick={() => { setView(4); setUrl("data-science") }} className={`${view === 4 ? 'text-white' : 'text-[#ffffff81]'} hover:text-white text-2xl cursor-pointer my-4 font-semibold flex items-center`}> <div className="bg-[#EA3453] mx-2 w-2 h-9"></div> Data Science Certification</li>
          <li onClick={() => { setView(5); setUrl("ai-for-healthcare-professional") }} className={`${view === 5 ? 'text-white' : 'text-[#ffffff81]'} hover:text-white text-2xl cursor-pointer my-4 font-semibold flex items-center`}> <div className="bg-[#EA3453] mx-2 w-2 h-9"></div> AI for Healthcare Professional</li>
        </ul>

        <div className="flex flex-col justify-center items-center md:w-[50%] w-[80%]">
          <p className='md:w-[78%] text-white text-lg my-5 text-justify'>{views[view]}</p>
          <a href={`/${url}`}><p className='text-[#EA3453] text-sm cursor-pointer'>Read more</p></a>
        </div>
      </div>

    </div>
  )
}

export default Courses