import React from 'react'

function Content(props) {
    return (
        <div className='text-white w-1/2 md:w-2/3 h-[50vh] overflow-y-scroll'>
            {
                props.content.map((value, index) => {
                    return (
                        <div className="pt-8" key={index}>
                            <p className='text-base md:text-lg font-semibold pb-2'>{value.heading}</p>
                            {value.desc.map((d,index)=>(d[0]==='$'?<li className='text-xs md:text-sm pb-2 font-bold' key={index}>{d.substr(1)}</li> :<li className='text-xs md:text-sm pb-2' key={index}>{d}</li>))}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Content