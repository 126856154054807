import React from "react";


function Descovery(props) {
  return (
    <div className="flex zoom items-center text-white justify-center flex-col">
      <img src="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772412/Need_Discovery_tuhwuo.png" />
      {/* // className=" w-[70px] h-[70px] rounded-full"> */}
      <div className="font-semibold mt-3">Need Discovery</div>
      <div className="text-center font-light mt-2">First, we take an in-depth look at your organization and identify needs on the corporate, team and individual level. </div>
    </div>
  )
}

function Delivery(props) {
  return (
    <div className="flex zoom items-center text-white justify-center flex-col">
      <img src="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772412/Training_Delivery_eu8swm.png" className=" w-[70px] h-[70px}" />
      <div className="font-semibold mt-3">Training Delivery</div>
      <div className="text-center font-light mt-2">The training are taught by experts in the field. You will get assessment of each candidate.
        You get well trained workforce !  </div>
    </div>
  )
}

function Schedule(props) {
  return (
    <div className="flex zoom items-center text-white justify-center flex-col">
      <img src="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772412/Training_Schedule_xzeruv.png" className=" w-[70px] h-[70px}" />
      <div className="font-semibold mt-3">Training Schedule</div>
      <div className="text-center font-light mt-2">Then we create a relevant and energizing training journey that is in line with your time and budget requirements. </div>
    </div>
  )
}
function Custom(props) {
  return (
    <div className="flex zoom items-center text-white justify-center flex-col">
      <img src="https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772413/Custom_Courses_myxjmt.png" className=" w-[70px] h-[70px}" />
      <div className="font-semibold mt-3">Custom Courses</div>
      <div className="text-center font-light mt-2">Get train your workforce on AI use cases specific to your business.
        Which will grant you edge over competitors</div>
    </div>
  )
}


const Section2 = () => {
  return (
    <div className="">
      <img src="https://i.ibb.co/tsZbJBs/Polygon-11.png" alt="" className="" />
      <div className=" xl:h-fit">
        <div className="bg-[#003953] pb-10 h-[100%] flex justify-center items-center">
          <div className="w-[95%] m-auto">
            <div className="p-3">
              <h1 className="text-4xl font-bold text-white text-center">
                Corporate Tranings
              </h1>
              <p className="text-white text-center mt-5 md:mt-3 w-[95%] text-sm md:text-base md:w-[80%] m-auto">
                We provide custom-fit-for-purpose trainings that brings your organizations to the next level. If you need to train many people in short duration, or you need to customize a training to your specific needs we are here to help you out.
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12 mt-16">
              <Descovery />
              <Delivery />
              <Schedule />
              <Custom />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
