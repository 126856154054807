import React, { useState } from 'react'
import Timeline from './Timeline'
import Content from './Content'

function IndustryValidatedCurriculam(props) {
    const [view, setView] = useState(0);
    const content = props.industryValidatedCurriculam

    return (
        <div className="bg-[#003953]">
            <div className='py-2'>
                <div className="w-[90%] mx-auto">
                    <h1 className='text-xl md:text-2xl font-semibold text-white py-6'>Industry Validated Curriculum</h1>
                    <div className="flex justify-between ">
                        <Timeline content={content} view={view} setView={setView} />
                        <Content className="text-[2rem]" content={content[view].content} view={view} />
                    </div>
                </div>
            </div>
            <img src="https://i.ibb.co/vsRWLG0/Polygon-4.png" alt="" className='' />
        </div>
    )
}

export default IndustryValidatedCurriculam