import React from "react";
const Section4 = () => {
  return (
    <div className="mb-10">
      <div className="font-bold text-4xl text-center mt-10 text-primary">
        Hire Talent
      </div>
      <div className="w-[85%] m-auto mt-7">
        Are you fed up with talent shortage and high rate of attrition, spending lot of your time, money and energy to train young talent, well we at Deepiotics faced it too. Our academy is here to help, discuss your specific need on tools and technologies with us to get regular vetted talent supply to your organization. Our students understand the IT ecosystem whether its client communication, Jira, code commits, API integration and testing. The complete readymade talent is available for you to onboard today !!
      </div>
      <div className="mt-8 space-y-10">
        <div className="flex w-screen space-x-10 px-16">
          <div className="w-[95%] h-auto bg-blue-500 hidden sm:flex justify-end overflow-hidden" style={{ background: `url('https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654092802/image1_eqkx9n.png') center center/cover` }}>
            <div className=" h-[140%] lg:h-[130%] relative left-10 bottom-10 lg:left-52 w-[70%] lg:w-[60%] bg-opacity-70 rotate-[30deg] bg-white"></div>
          </div>
          <div className="space-y-5 relative sm:right-[15%] right-0 py-8">
            <div className="text-2xl font-bold text-primary ">
              Senior only no longer works
            </div>
            <p>
              Salaries in the software industry are skyrocketing. They’re nearly 4 times the average.

            </p>
            <p>
              After Covid remunerations of Seniors have risen by more than 30%

            </p>
            <p>
              Job-hopping has become the new norm for some (senior) IT specialists.

            </p>
          </div>
        </div>
        <div className="flex w-screen space-x-10 px-16 flex-row-reverse">
          <div className="w-[80vw] hidden sm:flex h-auto bg-blue-500  justify-start overflow-hidden" style={{ background: `url(https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654092801/modern-equipped-computer-lab_16_ikl5js.png) center center/cover` }}>
            <div className="h-[140%] lg:h-[130%] relative -left-10 bottom-20 lg:bottom-0 lg:-left-52 w-[70%] lg:w-[60%] bg-opacity-70 rotate-[30deg] bg-white"></div>
          </div>
          <div className="space-y-5 relative sm:left-[15%] left-0 py-8">
            <div className="text-2xl font-bold text-primary ">
              Skilled talent is missing
            </div>
            <p>
              Shortage of engineers is the biggest threat tech companies face today

            </p>
            <p>
              There are a few specialist freshers available, but most lack the technical and soft skills needed to help companies power their business.            </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
