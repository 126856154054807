import React from 'react'

function Program(props) {
  return (
    <div className='flex flex-col justify-center zoom items-center p-3 w-56'>
        <img src={props.src} className="h-[50px] w-[50px]"/>
        <p className='font-semibold text-white'>{props.heading}</p>
        <p className='text-white text-justify'>{props.description}</p>
    </div>
  )
}

export default Program