import React from 'react'
import Program from './Program'
import { Parallax } from 'react-parallax'

const programs = [
  {
    heading: "Join Anytime",
    description: "Start anytime, get personal classes, then join batches.",
    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1649764001/ic_baseline-join-inner_jv4us3.png"
  },
  {
    heading: "Change Schedule",
    description: "Start anytime, get personal classes, then join batches.",
    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1649764002/bx_calendar-edit_oi796d.png"
  },
  {
    heading: "Weekend Classes",
    description: "Start anytime, get personal classes, then join batches.",
    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1649764001/bx_calendar-star_oqpchq.png"
  },
  {
    heading: "Need a Break",
    description: "Start anytime, get personal classes, then join batches.",
    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1649764001/emojione-monotone_palm-tree_blstkq.png"
  },
  {
    heading: "Missed Class",
    description: "Start anytime, get personal classes, then join batches.",
    src: "https://res.cloudinary.com/dusrmawop/image/upload/v1649764001/bx_calendar-x_mad9wy.png"
  }
]

function flexibleProgram() {
  return (
    <div className="">

      <img src="https://i.ibb.co/tsZbJBs/Polygon-11.png" alt="" className='' />
        <div className=" xl:h-[50vh]">
          <div className="bg-[#003953] h-[100%] flex justify-center items-center">
            <div className="w-[95%] m-auto">
              <div className="p-3">
                <h1 className='text-3xl font-semibold text-white'>Flexible Program</h1>
                <p className='text-white'>We have designed a flexible program as per  your personalized needs.`</p>
              </div>

              <div className="flex flex-wrap md:flex-row justify-evenly align-middle items-center py-8">
                {
                  programs.map((value, index) => {
                    return (<Program heading={value.heading} description={value.description} src={value.src} key={index} />)
                  })
                } 
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default flexibleProgram