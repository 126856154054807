import React from 'react'

function Sponsorship(props) {
  return (
    props.rev?
    <div className='flex flex-col md:justify-around md:flex-row-reverse py-3'>
        <img src={props.src} alt="" className='md:w-[25%]'/>
        <div className="md:w-[40%]">
            <h1 className='text-xl text-white text-justify font-semibold my-3'>{props.heading}</h1>
            <p className='text-white text-justify my-3'>{props.desc}</p>
            <button className="bg-[#EA3453] hover:bg-[#f43152] text-white font-bold py-1 px-6 my-3 rounded-full">Apply Now</button>
        </div>
    </div>
    :
    <div className='flex flex-col md:flex-row md:justify-around py-3'>
        <img src={props.src} alt="" className='md:w-[20%] h-[300px]'/>
        <div className="md:w-[40%]">
            <h1 className='text-xl text-white font-semibold my-3'>{props.heading}</h1>
            <p className='text-white text-justify my-3'>{props.desc}</p>
            <button className="bg-[#EA3453] hover:bg-[#f43152] text-white font-bold py-1 px-6 my-3 rounded-full">Apply Now</button>
        </div>
    </div>
  )
}

export default Sponsorship