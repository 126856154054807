import React from 'react'

function Instructor(props) {
  return (
    <div data-aos="fade-up" className='flex flex-col items-center  md:flex-row my-4'>
        <img src={props.src} alt="" className='w-2/3 rounded-xl md:w-1/6 2xl:w-1/12 my-3' />
        <div className=" text-justify px-4 flex flex-col justify-center items-center md:items-start md:justify-start md:text-left">
            <h1 className='text-xl md:text-base font-semibold'>{props.name}</h1>
            <p className='text-xs my-2'>{props.designation}</p>
            <p className='w-[90%] py-2'>{props.desc}</p>
        </div>
    </div>
  )
}

export default Instructor