import React from 'react'

function Card(props) {
  return (

    <div className='bg-[#003953] text-white rounded-xl h-80 m-4 md:m-1/4 md:w-1/4 flex flex-col items-center justify-start text-left'>
      <div className="w-2/3 lg:w-1/1 mt-16 lg:mt-12">
        <h1 className='text-base md:text-xl font-semibold py-2'>{props.name}</h1>
        <p className='text-sm'>{props.desc}</p>
      </div>
    </div>
  )
}

export default Card