import React from "react";
import "./About.scss";
import topImg from "../../assets/AboutSection/top-img.png";
import teamMember from "../../assets/AboutSection/team-member.png";
import line from "../../assets/AboutSection/Line.png";
import polygon from "../../assets/AboutSection/Polygon.png";
import section3Img from "../../assets/AboutSection/section3-img.png";

const About = () => {
  return (
    <>
      <div className="section1">
        <div className="section1_main">
          <div className="section1_text">
            <h1>We are the IT training Pioneers</h1>
            <p>
              At Deepiotics we realized the skill gap and missioned ourselves to
              become the bridge between learners and industry. We do this by our
              vast network of experienced professionals working in Deepiotics
              and other IT companies. <br />
              <br />
              Our students acquire skills to fulfil the current demand of
              industry on specific tools and technologies and boost their career
              right from their first job. <br />
              <br />
              <span>
                We train tomorrow's tech experts - Students and Professionals.
                Our courses are designed to fulfill job requirements in IT
                industry. We inspire a life-long passion for technologies and
                learning. Our mission is to connect talent, organizations and
                training resources
              </span>
            </p>
          </div>
        </div>

        <div className="section1_img">
          <img src={topImg} alt="section1_img" />
        </div>
      </div>

      <div className="section2 ">
        <h1 className="section2_heading">
          CREATING future IT Leaders <br /> Changing lives and companies
        </h1>

        <div className="section2_boxes">
          <div className="section2_box">
            High training standards and practice
          </div>

          <div className="section2_box">
            We Create mindset that solve complex problems
          </div>

          <div className="section2_box">
            Connect with students with in-depth knowledge, high potential, and
            motivation for building career in IT
          </div>

          <div className="section2_box">
            Selected Trainers who are industry experts and dedicated to
            providing students with best possible training.
          </div>

          <div className="section2_line">
            <p>
              Our students are prepared to meet and exceed the expectation of
              their future employers
            </p>
            <hr />
          </div>
        </div>
      </div>

      <div className="section3 ">
        <div className="section3_heading">
          <h1>Transforming lives and Industries</h1>
        </div>
        <div className="section3_boxes">
          <div className="section3_left_box">
            <p>
              For over a decade we've trained and mentored young students and
              professionals across the globe. <br />
              <br /> Many of our students have launched successful IT career
              with our trainings. Our students are gearing up the teams of
              hundreds of organizations, big, and small, local and
              multinational. We are a partner to all - students and companies.
              Their success is our success
            </p>
          </div>
          <div className="section3_right_box">
            <img src={section3Img} alt="section3Img" />
          </div>
        </div>
      </div>

      <div className="section4">
        <div className="section4_heading">
          <h1>OUR TEAM</h1>
        </div>
        <div className="section4_team">
          <div className="team_member">
            <img src="https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Rahul_nlizhh.jpg" alt="teamMemberImg" />
            <h1 className="font-bold">Mr. Rahul Jain</h1>
            <h2>Co-founder</h2>
          </div>
          <div className="team_member">
            <img src="https://res.cloudinary.com/dusrmawop/image/upload/v1650911511/Dr_Bhawna_New_2_natg62.jpg" alt="teamMemberImg" />
            <h1 className="font-bold">Dr. Bhawna Nigam</h1>
            <h2>Co-founder</h2>
          </div>
          <div className="team_member">
            <img src="https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Manisha_vbmdsl.jpg" alt="teamMemberImg" />
            <h1 className="font-bold">Ms. Manisha Sharma</h1>
            <h2>Data Scientist</h2>
          </div>
          <div className="team_member">
            <img src="https://res.cloudinary.com/dusrmawop/image/upload/v1650911510/Shraddha_gjhx9t.jpg" alt="teamMemberImg" />
            <h1 className="font-bold">Ms. Shraddha Gupta</h1>
            <h2>Machine Learning Engineer</h2>
          </div>
        </div>
      </div>

      <div className="section5">
        <div className="section5_corner">
          <figure className="figure">
            <img src={line} alt="line" className="line" />
            <img src={polygon} alt="polygon" className="polygon" />
          </figure>
        </div>
        <div className="section5_heading">
          <h1>JOIN US</h1>
        </div>
        <div className="section5_text">
          <p>
            At Deepiotics Academy we provide practical IT training to students
            and professionals to enhance their skills and start a career. If you
            are as passionate about educating and changing lives, and are
            willing to make an impact on thousands of lives and the IT industry
            altogether, then we'd love to hear from you
          </p>
        </div>
        <div className="section5_last_text">
          <h1>Let us know you are interested</h1>
          <span>---------�</span>
        </div>
      </div>
    </>
  );
};

export default About;
