import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import Card from './Card';

function Benifits() {

  const benifits = [
    {
      name: "Live Classes",
      desc: "Weekdays / Weekends live Interactive, Hands-on classes with 1:1 doubt clearing sessions"
    },
    {
      name: "1:1 Mentors",
      desc: "As soon as you join the course you will be allotted a mentor who will be with you throughout the course to help you out to your next level of job or goal."
    },

    {
      name: "Dedicated Lab",
      desc: "We will assign a dedicated lab on cloud with pre-installed editors, servers where you can practice in a simulated project environment that you will be getting on your job."
    },
    {
      name: "Real World Project",
      desc: "In the final week of course, You will work on a complex, hands-on project that challenges you to apply your learnings to a real-world problem with the collaborative Team ecosystem."
    },
    {
      name: "Job notifications",
      desc: "We will ensure that you will not miss any job opportunity once you successfully complete the course, We will send you notifications for latest job opportunities"
    },

  ]
  return (
    <div>
      <img src="https://i.ibb.co/vsRWLG0/Polygon-4.png" alt="" className='' />
      <div className="">
        <h1 className='px-10 md:px-10 text-xl mb-2 md:text-2xl font-semibold'>Deepiotics Academy Benefits</h1>

        {/* <Carousel dynamicHeight={false} showArrows={true} infiniteLoop={true} autoPlay={true} showIndicators={true} showThumbs={true} showStatus={false}> */}
          {/* <div className="flex justify-around"> */}

          <div className="flex flex-wrap lg:flex-nowrap">

            <Card name={benifits[0].name} desc={benifits[0].desc} no={1} />
            <Card name={benifits[1].name} desc={benifits[1].desc} no={2} />
            <Card name={benifits[2].name} desc={benifits[2].desc} no={3} />
          {/* </div> */}

          {/* <div className="flex justify-around"> */}
            <Card name={benifits[3].name} desc={benifits[3].desc} no={4} />
            <Card name={benifits[4].name} desc={benifits[4].desc} no={5} />
            {/* <Card name={benifits[5].name} desc={benifits[5].desc} no={index + 1} /> */}
          {/* </div> */}
          </div>
        {/* </Carousel> */}
      </div>
      <img src="https://i.ibb.co/tsZbJBs/Polygon-11.png" alt="" className='' />
    </div>
  )
}

export default Benifits