import React from 'react'

function Why(props) {

  return (
    <div data-aos="slide-up" className='flex flex-col  text-white md:w-2/3 lg:w-1/2 m-8'>
        {/* <div className='rounded-full my-4 w-10 h-10 bg-gray-400' alt=""></div> */}
        {/* <p className='text-2xl p-1'>{props.number}</p> */}
        <span className="bg-[#EA3453] font-semibold h-10 w-10 rounded-full flex justify-center items-center text-white ">{props.number}</span>
        <p className='py-3'>{props.desc}</p>
    </div>
  )
}

export default Why