import React from 'react'
import Instructor from './Instructor'

function MeetTheInstructor(props) {
    return (
        <div className="">
            <img src="https://i.ibb.co/vsRWLG0/Polygon-4.png" alt="" className=''/>
            <div className='py-2'>
                <div className="px-4 md:w-[90%] mx-auto">
                    <h1 className='text-xl md:text-2xl font-semibold'>Meet The Instructors</h1>
                    <div className="">
                        {
                            props.instructors.map((value, index) => {
                                return (
                                    <Instructor src={value.src} name={value.name} designation={value.designation} desc={value.desc} key={index} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <img src="https://i.ibb.co/tsZbJBs/Polygon-11.png" alt="" className='' />
        </div>
    )
}

export default MeetTheInstructor