import React from 'react'
import Why from './Why'
import graph from "../../../assets/Graph.png"

function WhyToChoose(props) {

    return (
        <>
            <div className="">
                <img src="https://i.ibb.co/tsZbJBs/Polygon-11.png" alt="" className='' />
                <div className="flex flex-col bg-[#003953]">
                    <div className="flex flex-col md:flex-row py-10">
                        <div className="md:w-1/2 text-white px-12 py-5">
                            <h1 className='text-4xl lg:text-5xl font-bold'>{props.whyHeading}</h1>
                            <p className='my-6 font-medium md:w-2/3 lg:w-2/3'>{props.whyDescription}</p>
                        </div>

                        <div className="md:w-1/2 grid grid-cols-2 grid-flow-row">
                            {
                                props.whyItems.map((value, index) => {
                                    return (
                                        <Why desc={value} number={index + 1} key={index}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* <img src={graph} alt="" className='md:w-1/2 mx-auto' />/ */}
                </div>
            </div>
        </>
    )
}

export default WhyToChoose