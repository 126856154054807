import React from "react";

const Item = ({ img, title, desc }) => {
  return (
    <div className="flex justify-center space-x-4 items-center my-2 md:my-5">
      <div>
        <div
          className="h-[60px] w-[60px]"
          style={{ background: `url(${img}) center center/cover` }}
        ></div>
      </div>
      <div>
        <div className="lg:text-lg text-sm font-semibold">{title}</div>
        <div className="w-[170px] lg:text-sm text-xs lg:w-[210px]">{desc}</div>
      </div>
    </div>
  );
};

const Whyus = () => {
  return (
    <div className="lg:px-20 px-5">
      <div className="py-8">
        <div className="text-primary text-center text-3xl font-bold">Why Us?</div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 mt-9">
          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img2_le1tzx.png"
            title="Industry Curriculum"
            desc="Job Oriented Curriculum designed with our Advisors & partner companies"
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785423/img_kdxjxn.png"
            title="Flexible"
            desc="Live, Hands-on Classes 
            "
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img3_nppzar.png"
            title="Internship"
            desc="Internship support with Deepiotics network
            "
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img4_rsdut4.png"
            title="Industry Trainers"
            desc="Trainer provides Real World Exposure
            "
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img5_kwgmpi.png"
            title="Live Projects"
            desc="Develop Real World Projects
            "
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img6_qbzbhm.png"
            title="Community"
            desc="Join Community learners from 
            across the World"
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img7_gc82ks.png"
            title="Sponsorship"
            desc="Get Sponsored
            "
          />

          <Item
            img="https://res.cloudinary.com/dusrmawop/image/upload/v1649785422/img8_mwcfzd.png"
            title="Certification"
            desc="Get Certificate on Successful Completion
            "
          />
        </div>
      </div>

      <div className="mt-5 flex items-center justify-center">
        <div className="text-lg text-center md:text-xl font-semibold py-5">
          Our Instuctors are from top Companies
        </div>
      </div>

      <div className="mt-2 mb-4 justify-center flex flex-wrap items-center">

        <img
          width={100}
          src="https://res.cloudinary.com/dusrmawop/image/upload/v1649784554/image_1238_dhljpf.png"
          className="mx-6"
        />

        <img
          width={120}
          src="https://res.cloudinary.com/dusrmawop/image/upload/v1649784554/image_1239_zmvrzk.png"
          className="mx-6"
        />

        <img
          width={120}
          src="https://res.cloudinary.com/dusrmawop/image/upload/v1649784554/image_1237_i4iuq4.png"
          className="mx-6"
        />
      </div>
    </div>
  );
};

export default Whyus;
