import React from 'react'

function Partner(props) {
  return (
    <div className="flex items-center text-white justify-center flex-col">
      <img src='https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772412/Research_Development_Departments_merlnj.png' className="bg-gray-200 w-[70px] h-[70px]"></img>
      <div className="font-bold mt-3 text-center text-blue-800">Research & Development Departments</div>

    </div>
  )
}

function Partner1(props) {
  return (
    <div className="flex items-center text-white justify-center flex-col">
      <img src='https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772412/Universities_jmsbc4.png' className="bg-gray-200 w-[70px] h-[70px] "></img>
      <div className="font-bold mt-3 text-blue-800">Universities</div>

    </div>
  )
}

function Partner2(props) {
  return (
    <div className="flex items-center text-white justify-center flex-col">
      <img src='https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772412/Product_Companies_qegbno.png' className="bg-gray-200 w-[70px] h-[70px] "></img>
      <div className="font-bold mt-3 text-blue-800">Product Companies</div>

    </div>
  )
}
function Partner3(props) {
  return (
    <div className="flex items-center text-white justify-center flex-col">
      <img src='https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772413/College_qadu6p.png' className="bg-gray-200 w-[70px] h-[70px]"></img>
      <div className="font-bold mt-3 text-blue-800">Colleges</div>

    </div>
  )
}

function Partner4(props) {
  return (
    <div className="flex items-center text-white justify-center flex-col">
      <img src='https://res.cloudinary.com/dq5v1ewpq/image/upload/v1654772413/AI_Development_zd2el8.png' className="bg-gray-200 w-[70px] h-[70px] "></img>
      <div className="font-bold mt-3 text-blue-800"> AI Development
        Companies</div>

    </div>
  )
}

const Section3 = () => {
  return (
    <div>
      <img src="https://i.ibb.co/vsRWLG0/Polygon-4.png" alt="" className='' />
      <div className='font-bold text-4xl text-center mt-10'>Partner With Us</div>
      <div className='mt-3 text-sm text-center'>With our access to young and talented pool we are fulfilling the growing demand of skills with our partner companies. </div>
      <div className='text-center font-bold mt-8 text-lg'>Our preferred partners includes :</div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-8 px-10'>
        <Partner></Partner>
        <Partner1></Partner1>
        <Partner2></Partner2>
        <Partner3></Partner3>
        <Partner4></Partner4>
      </div>
      <hr className='w-[80%] m-auto my-16 border-4 border-blue-400' />
    </div>
  )
}

export default Section3