import React, { useEffect, useState } from 'react'
import Modal from '../../Modal';
import axios from "axios"

const Faq = (props) => {
    const [scroll, setScroll] = useState(0);
    const [docHeight, setDocHeight] = useState(2000);
    const [user, setUser] = useState({ name: "", email: "", contact: "", code: "" })
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const loadRazorpay = (src) => {
        return new Promise(resolve => {
            const script = document.createElement('script')
            script.src = src;
            script.onload = () => { resolve(true) }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    }

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const displayRazorpay = async () => {

        const res = await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            alert("Razorpay SDK FAILED. Are You Online ? ");
            return
        }

        const userInfo = {
            name: user.name,
            contact: user.contact,
            email: user.email,
            course: props.heading,
            code: user.code
        }
        const { name, contact, email } = userInfo
        if (name && email && contact) {
            const data1 = await axios.post("https://deepioticsacademy.herokuapp.com/razorpay", userInfo)
            const data = data1.data

            var options = {
                key: "rzp_live_fn88sjd082jV6M",
                amount: data.amount,
                currency: data.currency,
                name: "Deepiotics",
                description: props.heading,
                image: "https://res.cloudinary.com/dq5v1ewpq/image/upload/v1655455900/logo_reuowf.png",
                order_id: data.id,
                handler: function (response) {
                    setModalIsOpen(false);
                    alert(`Payment ID: ${response.razorpay_payment_id}`);
                    alert(`Order ID: ${response.razorpay_order_id}`);
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert("Fill All The Details")
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY);
        })
        setDocHeight(document.body.scrollHeight - 1500)
    }, [])

    return (
        <div>
            <Modal heading={props.heading} displayRazorpay={displayRazorpay} handleChange={handleChange} user={user} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} amount={props.fees} />
            <div className='flex flex-col md:flex-row'>
                <div className='w-[640px] py-10 md:w-[1280px]  bg-[#EA3453]'></div>
                <div className='py-4 md:py-0 md:w-[35%] bg-[#003953]'>
                    <h1 className='text-center p-5 text-xl text-white text font-bold'>Watch Free Webinar</h1>
                    <p className='text-center p-4 px-9 text-lg text-white text'>Listen to our mentors explaining more about the Python course. We will share more insights about starting a career in Python. Payscale, Current trends.</p>
                    <ul className='text-center'>
                        <form method="POST">
                            <li className="text-white text-md pb-2 font-semibold cursor-pointer">
                                <input
                                    type="text"
                                    name="name"
                                    //   value={user.name}
                                    //   onChange={handleChange}
                                    placeholder="Full Name"
                                    className="rounded-3xl bg-[#003953] border-white border-2 formInput w-[70%]" />
                            </li>
                            <li className="text-white text-md pb-2 font-semibold  cursor-pointer">
                                <input
                                    type="email"
                                    name="email"
                                    //   value={user.email}
                                    //   onChange={handleChange}
                                    placeholder="Email ID"
                                    className="rounded-3xl bg-[#003953] border-white border-2 w-[70%] formInput" />
                            </li>
                            <li className="text-white text-md pb-2 font-semibold  cursor-pointer">
                                <input
                                    type="text"
                                    name="message"
                                    //   value={user.message}
                                    //   onChange={handleChange}
                                    placeholder="Mobile Number"
                                    className="rounded-3xl bg-[#003953] border-white border-2 w-[70%] formInput" />
                            </li>
                            <li className="text-white text-md pb-2 font-semibold cursor-pointer">
                                <button className='bg-[#EA3453] rounded-3xl font-semibold w-[35%] m-1 p-2 mb-10'>Apply Now</button>
                            </li>
                        </form>
                    </ul>


                </div>
            </div>
            <div className='flex-col flex'>
                <h1 className='font-bold text-2xl px-20 pt-8'>FAQ's</h1>
                <div className='p-6  w-[93%] self-center  '>
                    {
                        props.faqs.map((value, index) => {
                            return (
                                <details className='cursor-pointer px-3 p-3 m-3 rounded-xl border-black border-2' key={index}>
                                    <summary className='font-semibold'>{value.question}</summary>
                                    <p>{value.answer}</p>
                                </details>
                            )
                        })
                    }
                </div>
            </div>

            <div>
                <div className={`bg-[#EA3453] flex flex-row p-5 ${scroll >= docHeight ? '' : 'fixed'} w-[100vw] bg-opacity-90 z-[200] bottom-0 text-white justify-between `}>
                    <div className="flex">
                        <div className='flex flex-col px-3 md:px-5'><div className='font-bold text-sm whitespace-nowrap md:text-lg'> Fees</div><div>{props.fees}</div></div>
                        <div className='flex flex-col px-3 md:px-5'><div className='font-bold text-sm whitespace-nowrap md:text-lg'> Start Date</div><div>{props.startDate}</div></div>
                        <div className='flex flex-col px-3 md:px-5'><div className='font-bold text-sm whitespace-nowrap md:text-lg'> Seats </div><div>{props.seats}</div></div>
                    </div>
                    <button className='bg-[#003953] rounded-3xl p-3 font-semibold' onClick={() => setModalIsOpen(true)} >Apply Today</button>
                </div>
            </div>
        </div>
    )
}

export default Faq