import React from 'react'

function Frontpage() {
  return (
    <div className='p-16'>
      <div >
        <h1 className='font-bold text-5xl text-[#003953] text-center w-full'>Boost your IT career</h1>
        <h1 className='font-bold text-5xl text-[#003953] text-center w-full'>with Industry Professionals</h1>

        {/*  text-3xl  w-full  text-center md:w-[65%] xl:w-[50%] sm:text-3xl md:text-4xl lg:text-5xl text-[#003953] justify-center items-center */}

        <div className=" mt-10 md:justify-between md:flex-col md:items-center">
          <ul className='flex m-auto w-[80%] flex-wrap align-center justify-center'>

            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Industry Curriculum</li>

            <li className='text-[#003953] hidden md:block px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>|</li>
            {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}

            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Live Hands on Classes </li>
            <li className='text-[#003953] hidden md:block px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>|</li>
            {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}
            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Live Projects </li>
            {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}
          </ul>
        </div>

        <div className=" md:justify-between  md:flex-col md:items-center">
          <ul className='flex m-auto w-[80%] flex-wrap align-center justify-center'>
            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Internship
              {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}
            </li>
            <li className='text-[#003953] hidden md:block px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>|</li>
            {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}
            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Flexible Programme </li>
            <li className='text-[#003953] hidden md:block px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>|</li>
            {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}
            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Sponsorship </li>
          </ul>
        </div>

        <div className=" md:justify-between  md:flex-col md:items-center">
          <ul className='flex m-auto w-[80%] flex-wrap align-center justify-center'>
            {/* <li className='text-[#003953] block md:hidden px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>-----</li> */}

            <li className='text-[#EA3453] text-center px-2 font-bold my-[1%] text-2xl sm:text-xl md:text-2xl 2xl:text-2xl'>Job Support </li>
          </ul>
        </div>


      </div >

      {/* <div className="triangle top-[15%] mx-auto"></div> */}
    </div >
  )
}

export default Frontpage