import React from 'react'
// import Layout from "../../components/Layout";
import { useState } from 'react'
import axios from "axios"
import "./businessContact.css"

const url = "https://deepioticsacademy.herokuapp.com/sponsor"

const Sponsor = () => {
  const [name,setName]=useState("")
  const [company,setCompany]=useState("")
  const [contact,setContact]=useState("")
  const [email,setEmail]=useState("")
  const [numberofCandidate,setNumberofCandidate]=useState("")
  const [interested,setInterested]=useState("")
  const [msg,setMsg]=useState("")

  const handleSubmit=(e)=>{
    e.preventDefault()
    console.log(name,company,contact,
        email,interested,numberofCandidate,
        msg)
    try {
      const res = axios.post(url,{
        name,
        companyName:company,
        contactPerson:contact,
        email,
        interestedInSponsoring:interested,
        noOfCandidates:numberofCandidate,
        c:msg
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
      <div className='Sponsor'>
        <div className="container">
          <div className="bottom">
            <div className="left">
              
              <div className="heading">
                <h1>Sponsor Candidate in need</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Username</label>
                  </div>  
                  <input className='formInputs' type="text" minLength={3} required onChange={(e)=>setName(e.target.value)}/>
                </div>
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Company Name</label>
                  </div>  
                  <input className='formInputs' type="text" minLength={3} required onChange={(e)=>setCompany(e.target.value)}/>
                </div>
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Contact Person</label>
                  </div>  
                  <input className='formInputs' type="tel" minLength={4} required onChange={(e)=>setContact(e.target.value)}/>
                </div>
                
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel' minlength="4">Email</label>
                  </div>
                  <input className='formInputs' type="email" required onChange={(e)=>setEmail(e.target.value)}/>
                </div>

                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel' minlength="4">Interested in sponsoring:</label>
                  </div>
                  <select className='formInputs' required value={interested} onChange={(e)=>setInterested(e.target.value)}>
                    <option value="Person with Disability">Person with Disability</option>
                    <option value="Below poverty line students">Below poverty line students</option>
                  </select>
                </div>

                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel' minlength="4">Number of Candidates</label>
                  </div>
                  <input className='formInputs' type="number" required onChange={(e)=>setNumberofCandidate(e.target.value)}/>
                </div>
                
    
                
                <div className="inputBoxes">
                  <div className='labelBox'>
                    <label className='formLabel'>Message</label>
                  </div>
                  <textarea cols="30" rows="2" className='formInputs' required onChange={(e)=>setMsg(e.target.value)}></textarea>
                </div>
                <div className="btnbox">
                  <button className="btn">GET IN TOUCH</button>
                </div>   
              </form>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Sponsor