import React from "react";

const Navbar = () => {

  return (
    <nav className=" border-gray-200 md:flex md:justify-between px-2 sm:px-4 py-2.5 bg-[#003953]">
      <div className="container flex flex-wrap justify-between items-center mx-auto max-w-none">
        <a href="/" className="flex w-1/3 sm:w-1/2 md:w-auto items-center">
          <img
            src="https://i.ibb.co/yP8tfJh/logo.png"
            className="mr-3 md:w-1/2"
            alt="Logo"
          />
        </a>

        <button
          data-collapse-toggle="mobile-menu-4"
          type="button"
          className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-[#003953] dark:focus:[#003953]"
          aria-controls="mobile-menu-4"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div
        className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1 md:px-8"
        id="mobile-menu-4"
      >
        <ul className="flex justify-center items-center flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
          <button
            id="dropdownDefault"
            // onClick={handleDropdown}
            data-dropdown-toggle="dropdownCourses"
            className="text-white font-medium rounded-lg text-sm py-2.5 text-center inline-flex items-center "
          >
            Courses
            <svg
              className="w-4 h-4 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          <div
            id="dropdownCourses"
            className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
          >
            <ul
              className="py-1 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefault"
            >
              <li>
                <a
                  href=" /python-for-everyone"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Python for Everyone
                </a>
              </li>
              <li>
                <a
                  href=" /python-for-data-analysis"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Certification in Data Analytics
                </a>
              </li>
              <li>
                <a
                  href=" /python-for-researcher"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Python for Researcher
                </a>
              </li>
              <li>
                <a
                  href=" /research-paper-writing"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Research Paper Writing
                </a>
              </li>
              <li>
                <a
                  href=" /data-science"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Data Science Certification
                </a>
              </li>
              <li>
                <a
                  href=" /ai-for-healthcare-professional"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  AI for Healthcare Professional
                </a>
              </li>
            </ul>
          </div>

          <li>
            <a
              href=" /business"
              className="block whitespace-nowrap py-2 pr-4 pl-3 text-gray-100 bg-blue-700 rounded md:bg-transparent md:p-0 md:dark:hover:text-blue-400"
            >
              For Business
            </a>
          </li>
          <li>
            <a
              href=" /about"
              className="block whitespace-nowrap py-2 pr-4 pl-3 text-gray-100 bg-blue-700 rounded md:bg-transparent md:p-0 md:dark:hover:text-blue-400"
            >
              About
            </a>
          </li>
          <li>
            <a
              href=" /sponsor"
              className="block whitespace-nowrap py-2 pr-4 pl-3 text-gray-100 bg-blue-700 rounded md:bg-transparent md:p-0 md:dark:hover:text-blue-400"
            >
              Sponsor a Candidate
            </a>
          </li>
          <li>
            <a
              href=" /contact"
              className="block whitespace-nowrap py-2 pr-4 pl-3 text-gray-100 bg-blue-700 rounded md:bg-transparent md:p-0 md:dark:hover:text-blue-400"
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href="/hire">
              <button type="button" className="text-white whitespace-nowrap bg-[#EA3453] hover:bg-[#f43152] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"> Hire From Us
              </button>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
