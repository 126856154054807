import React from 'react'
import Advisor from './Advisor'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function OurAdvisors() {

    const advisors = [
        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653239067/Mr._Rajat_Maheshwari_vsfxrk.png",
            name: "Mr. Rajat Maheshwari ",
            designation: "Vice President, Mastercard"
        },

        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653239066/Mr._Amit_Varma_k6f4p5.png",
            name: "Mr. Amit Varma",
            designation: "Co-Founder, BRAINTOY"
        },

        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653239068/Mr._Sourov_Moitra_qnkqah.png",
            name: "Mr. Sourov Moitra",
            designation: "Director, Data Science R&D at Neustar"
        },

        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653239066/Mr._Kapil_Mohan_hdqr1p.png",
            name: "Mr. Kapil Mohan",
            designation: "Director, Product Engineering, Optum"
        },
        
        {
            src: "https://res.cloudinary.com/dusrmawop/image/upload/v1653239066/Ms._Lovee_Jain_pbsssz.png",
            name: "Ms. Lovee Jain",
            designation: "Software Developer, Ballarat City Council"
        },

    ]

    return (
        <div className="">
            <img src="https://i.ibb.co/vsRWLG0/Polygon-4.png" alt="" className=''/>
            <div className="w-[95%] m-auto">
                <div className="p-3">
                    <h1 className='text-3xl font-semibold'>Our Advisors</h1>
                    <p className=''>Our experienced Advisor who are  the Industry Leaders will help you to guide for your career Roadmap</p>
                </div>

                <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} showIndicators={false} showThumbs={false} showStatus={false}>
                    <div className="flex w-screen flex-wrap md:flex-nowrap md:w-full justify-evenly align-middle items-center py-8">
                        {
                            advisors.map((value,index) => {
                                return (<Advisor name={value.name} designation={value.designation} src={value.src} key={index}/>)
                            })
                        }
                    </div>
                    <div className="flex w-screen flex-wrap md:flex-nowrap md:w-full justify-evenly align-middle items-center py-8">
                        {
                            advisors.map((value,index) => {
                                return (<Advisor name={value.name} designation={value.designation} src={value.src} key={index}/>)
                            })
                        }
                    </div>
                </Carousel>
            </div>
            <img src="https://i.ibb.co/tsZbJBs/Polygon-11.png" alt="" className=''/>
        </div>

    )
}

export default OurAdvisors