import React from 'react'
import './Section1.scss';
import { MdArrowForward } from 'react-icons/md'
import { Navigate, Link } from 'react-router-dom';

const Box1 = () => {
    return (
        <div className='bg-yellow-400'>
            <div className='flex flex-col justify-start md:p-[3rem] md:pb-[1.5rem] pb-[0.75rem] p-[1rem] sm:p-[1.5rem]  xl:p-[5rem] xl:pb-[2rem] '>
                <div className='font-bold text-lg'>Corporate Tranings</div>
                <div className='w-[70%] mt-2'>Upskill your workplace to keep up with the demands of your business.</div>
            </div>
            <div className='w-full flex justify-end mt-1 px-8 py-4 lg:p-10 md:p-8'>
                <Link to="/training">
                    <MdArrowForward size={32} />
                </Link>
            </div>
        </div>
    )
}

const Box2 = () => {
    return (
        <div className='bg-yellow-400'>
            <div className='flex flex-col justify-start md:p-[3rem] md:pb-[1.5rem] pb-[0.75rem] p-[1rem] sm:p-[1.5rem]  xl:p-[5rem] xl:pb-[2rem] '>
                <div className='font-bold text-lg'>Hire Talent</div>
                <div className='w-[70%] mt-2'>Well trained techies ready to take the job with full enthusiasm.</div>
            </div>
            <div className='w-full flex justify-end mt-1 px-8 py-4 lg:p-10 md:p-8'>
                <Link to="/hire">
                    <MdArrowForward size={32} />
                </Link>
            </div>
        </div>
    )
}

const Box3 = () => {
    return (
        <div className='bg-yellow-400'>
            <div className='flex flex-col justify-start md:p-[3rem] md:pb-[1.5rem] pb-[0.75rem] p-[1rem] sm:p-[1.5rem]  xl:p-[5rem] xl:pb-[2rem] '>
                <div className='font-bold text-lg'>Partner With Us</div>
                <div className='w-[70%] mt-2'>We invite universities, tech companies for strategic alliances with our academy.</div>
            </div>
            <div className='w-full flex justify-end mt-1 px-8 py-4 lg:p-10 md:p-8'>
                <Link to="/partner">
                    <MdArrowForward size={32} />
                </Link>
            </div>
        </div>
    )
}

const Box4 = () => {
    return (
        <div className='bg-yellow-400'>
            <div className='flex flex-col justify-start md:p-[3rem] md:pb-[1.5rem] pb-[0.75rem] p-[1rem] sm:p-[1.5rem]  xl:p-[5rem] xl:pb-[2rem] '>
                <div className='font-bold text-lg'>Sponsor Candidate</div>
                <div className='w-[70%] mt-2'>Provide sponsorship to the person with disability or financially weak but deserving candidate.</div>
            </div>
            <div className='w-full flex justify-end mt-1 px-8 py-4 lg:p-10 md:p-8'>
                <Link to="/sponsor">
                    <MdArrowForward size={32} />
                </Link>
            </div>
        </div>
    )
}


const Section1 = () => {
    return (
        <div className=''>
            <div className='bg-[#003953] h-[40vh] '></div>
            <div className='flex items-center justify-center px-8 py-2 flex-col w-screen relative -top-[25vh]'>
                <div className='text-center underline font-bold text-3xl text-white underline-offset-2'>Cost Effective Talent Available!  </div>
                <div className='grid items-center gap-10 md:grid-cols-2 grid-cols-1 grid-flow-row mt-8 '>
                    <Box1 />
                    <Box2 />
                    <Box3 />
                    <Box4 />
                </div>
            </div>
        </div>
    )
}

export default Section1